import React from 'react'
import filter from '../../../assets/filter.png'

const CoursesFilter = ({ showModal, setShowModal }) => {
	return (
		<>
			<button
				className='border-2 border-skyblue rounded-lg mr-10 px-7 py-2 flex items-center mt-[90px] cursor-pointer z-40'
				onClick={() => setShowModal(!showModal)}>
				<img
					src={filter}
					alt='filter'
					className='mr-4'
				/>
				<p className='text-skyblue'>Фильтры и сортировка</p>
			</button>
		</>
	)
}

export default CoursesFilter
