import React, { useEffect, useState } from 'react';
import Registration from "./pages/User/Registration/Registration";
import Authorization from "./pages/User/Authorization/Authorization";
import Home from "./pages/home/Home"
import { routes } from "./routes/routes";
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { checkAuth } from "./store/reducers/CheckAuthReducer"
import Jobs from './pages/Jobs/Jobs';
import Documents from './pages/Documents/Documents';
import TermsOfUse from './pages/Documents/TermsOfUse';
import PrivacyPolicy from './pages/Documents/PrivacyPolicy';
import PublicOffer from './pages/Documents/PublicOffer';
import InIt from './pages/InIt/InIt';
import Events from './pages/Events/Events';
import Internships from './pages/Internships/Internships';
import VacanciesList from './pages/Jobs/VacanciesList';
import Courses from './pages/InIt/Courses/Courses';
import EventsList from './pages/Events/EventsList';
import InternshipsList from './pages/Internships/InternshipsList';
import DetailCourses from './pages/InIt/Courses/DetailCourses';

import { TemporaryPage } from './pages/home/TemporaryPage';
import Articles from './pages/InIt/Articles/Articles';
import Telegram from './pages/Telegram/Telegram';
import { Placeholder } from "./pages/Placeholder/Placeholder"
import { Mentors } from './pages/Mentors/Mentors';
import { MentorDetail } from './pages/Mentors/MentorDetail';

function App() {
	const dispatch = useDispatch();
	useEffect(() => {
		if (localStorage.getItem('token')) {
			dispatch(checkAuth())
		}
	}, [])

	return (
		<Routes>
			<Route exact path={routes.home} element={<Home />} />
			<Route path={routes.registration} element={<Registration />} />
			<Route path={routes.authorization} element={<Authorization />} />
			<Route path={routes.jobs} element={<Jobs />} />
			<Route path={routes.detailJobs} element={<Jobs />} />

			<Route path={routes.mentors} element={<Placeholder />} />
			<Route path={`${routes.mentors}/:id`} element={<Placeholder />} />

			<Route path={routes.events} element={<Events />} />
			<Route path={routes.internships} element={<Internships />} />
			<Route path={routes.it} element={<InIt />}>
				<Route path={routes.courses} element={<Courses />} />
				<Route path={routes.detailCurses} element={<DetailCourses />} />
				<Route path={routes.event} element={<EventsList />} />
				<Route path={routes.internship} element={<InternshipsList />} />
				<Route path={routes.job} element={<VacanciesList />} />
				<Route path={routes.enterITArticles} element={<Articles />} />
			</Route>
			<Route path={routes.documents} element={<Documents />} />
			<Route path={routes.termsOfUse} element={<TermsOfUse />} />
			<Route path={routes.publicOffer} element={<PublicOffer />} />
			<Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />

			<Route path={'*'} element={<TemporaryPage />} />
			<Route path={routes.telegram} element={<Telegram />} />
			<Route path={routes.placeholder} element={<Placeholder />} />

		</Routes>
	);
}

export default App;
