import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow.svg'
import { ReactComponent as CrossIcon } from '../../../../../assets/cross-grey-for-select.svg'
import { ReactComponent as CrossIconForChips } from '../../../../../assets/crossForChips.svg'
import { setActiveSubcatecory } from '../../../../../store/reducers/EnterItArticlesReducer'

const SubcategoriesSelect = () => {
  const dispatch = useDispatch()

  const categories = useSelector(
    state => state.enterItArticles.categories
  ).filter((category) => category.isActive)
  const selectHeading = categories.map((category) => category.label).join(', ')
  const options = categories.flatMap(category => category.subcategories.map(subcategory => subcategory))

  const onChangeHandler = (options) => {
    const changedOptions = options.map(option => option = {...option, isActive: !option.isActive})
    dispatch(setActiveSubcatecory(changedOptions))
  }  

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowIcon />
      </components.DropdownIndicator>
    )
  }

  const ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <CrossIcon />
      </components.ClearIndicator>
    )
  }

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <CrossIconForChips />
      </components.MultiValueRemove>
    )
  }

  const customStyles = {
    container: (defaultStyles) => ({
      ...defaultStyles,
      minHeight: '40px',

    }),
    option: (defaultStyles) => ({
      ...defaultStyles,
      border: 'none',
      fontSize: '14px',
      fontWeight: '600',
      color: '#808099'
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      boxShadow: 'none',
      fontSize: '14px',
      minHeight: '40px',
      maxWidth: '488px',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: () => ({ padding: '0 10px 0 0' }),
    placeholder: (defaultStyles) => ({ ...defaultStyles, fontSize: '14px', fontWeight: '600' }),
    multiValue: (defaultStyles) => ({
      ...defaultStyles,
      background: 'none',
      height: '28px',
    }),
    multiValueLabel: () => ({ 
      fontSize: '14px',
      fontWeight: '600',
      color: '#808099',
      background: '#F2F6FF',
      padding: '5px 10px',
      borderRadius: '100px'
    }),
    multiValueRemove: () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '15px',
      width: '15px',
      borderRadius: '100px',
      background:' #BEBFD2'
    })
  }

  return (
    categories.length ? (
      <div>
        <div className="text-[14px] text-[#808099] font-semibold leading-[130%] min-h-[18px] mb-[10px]">
          {selectHeading}
        </div>
        <Select
          isMulti
          options={options}
          onChange={onChangeHandler}
          styles={customStyles}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}          
          placeholder={'Выберите подкатегорию...'}
        />
      </div>
    ) : ''
  )
}

export default SubcategoriesSelect
