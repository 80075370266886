import { useEffect } from "react";

export const useOutsideClick = (elementRef, handler, show) => {
  useEffect(() => {
    if(!show) return;

    const handleClick = (e) => {
      if (elementRef.current && !elementRef.current.contains(e.target)) {
        e.preventDefault();
        e.stopPropagation();
        handler();
      }
    };

    document.addEventListener('click', handleClick, { capture: true });
    return () => {
      document.removeEventListener('click', handleClick, { capture: true });
    };
  }, [elementRef, handler, show])
};