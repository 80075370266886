import React, { useState } from 'react'
import { useEffect } from 'react'
import { NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom'
import Header from '../../common/Header/Header'
import Sidebar from '../../common/sidebar/Sidebar'
import { routes } from '../../routes/routes'
import ModalEvents from '../Events/ModalEvents'
import ModalInternships from '../Internships/ModalInternships'
import ModalCourses from './Courses/ModalCourses'
import FilterBar from './FilterBar'

const InIt = () => {
	const [showModal, setShowModal] = useState(false)
	const navigate = useNavigate()
	const { pathname } = useLocation()

	// useEffect (() => {
	//   navigate(routes.courses)
	// }, [])

	const renderModal = pathname => {
		switch (pathname) {
			case '/it/courses':
				return showModal ? (
					<ModalCourses
						showModal={showModal}
						setShowModal={setShowModal}
					/>
				) : null
			// case "/it/event":
			//     return showModal ? <ModalInternships showModal={showModal} setShowModal={setShowModal}/> : null;
			// case "/it/internship":
			//     return showModal ? <ModalEvents showModal={showModal} setShowModal={setShowModal}/> : null;
			default:
				return ''
		}
	}

	return (
		<div>
			<div className='homePageContainer'>
				<Sidebar />

				<div className='mainContentContainer'>
					<Header headerContent={<h2 className='flex items-center m-0 text-base'>Войти в IT</h2>} />
					<div className=''>
						<FilterBar
							showModal={showModal}
							setShowModal={setShowModal}
						/>
						{renderModal(pathname)}
						<div className='container mx-auto w-[100%] flex flex-col items-center'>
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default InIt
