import { configureStore } from "@reduxjs/toolkit";
import { registrationReducer } from "./reducers/RegistrationReducer";
import { vacanciesReducer } from "./reducers/VacanciesReducer";
import { enterItArticlesReducer } from "./reducers/EnterItArticlesReducer";

export const store = configureStore({
	reducer: {
		registration: registrationReducer,
		vacancies: vacanciesReducer,
		enterItArticles: enterItArticlesReducer,
	},
});
