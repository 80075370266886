import { Link } from "react-router-dom";
import { routes } from "../../../../../../routes/routes";

export const authFormSchema = [
  {
    label: "Электронная почта",
    placeholder: "Введите ваш email",
    name: "email",
    type: "email",
  },
  {
    label: (
      <>
        Пароль
        <Link to={routes.forgotPassword} className="text-blue-600">
          Забыли пароль?
        </Link>
      </>
    ),
    placeholder: "Введите пароль",
    name: "password",
    type: "password",
  },
];
