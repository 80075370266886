import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg'
import filtersIcon  from '../../../../assets/filters.svg'
import BadgedButtonForMobile from './BadgedButtonForMobile'

const SearchBarForMobile = ({ filtersButtonHandler }) => {
  const filtersCounter = useSelector(state => state.enterItArticles.filtersCounter)

  return (
    <div className="hidden sm:flex flex-row items-center gap-x-[11px] -ml-1">
      <div className="w-[300px] h-[48px] p-[15px] border border-[#E7EEFF] rounded-[10px] flex flex-row justify-between items-center">
        <input 
          type="text" 
          name="articles-search" 
          id="articles-search" 
          placeholder="Поиск"
          className="w-full h-full text-[14px] font-semibold text-[#BEBFD2] bg-transparent 
                     focus:outline-none focus:text-[14px] focus:font-semibold"
        />
        <button className=""><SearchIcon/></button>
      </div>
      <BadgedButtonForMobile counter={filtersCounter} icon={filtersIcon} handler={filtersButtonHandler}/>
    </div>
  )
}

export default SearchBarForMobile
