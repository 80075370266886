import React from 'react';
import js from '../../../assets/courses1.png'
import offer from '../../../assets/offer.png'
import speaker from '../../../assets/speaker1.png'
import speakerTwo from '../../../assets/speaker2.png'

import { useNavigate, useParams } from 'react-router';

const DetailCourses = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const goBack = () => navigate(-1)

    const course = {

        logo: js,
        company: 'Geekbrains',
        title: 'JavaScrit для начинающих',
        preview:
         'В данном курсе рассмотрены основы программирования на JavaScript а также некоторые инструменты и модели данных, необходимые для практического использования JavaScript. ',
        location: 'Offline, г. Минск, ул. Таборианина д.29, оф.36',
        start: 'Дата старта: По набору группы',
        classes: '3,5 месяца (30 занятий, 90 часов)',
        price: '850 бел.руб.',
        sale: '(скидка 50%, экономия 850 бел.руб.)',
        level: 'Начало карьеры',
        direction: 'Frontend', 
        speaker: speaker,
        speakerTwo: speakerTwo,
        nameSpeaker: "Климко Екатерина",
        postSpeaker: "Senior Frontend developer",
        companySpeaker: "EPAM",
        img: offer,
        people: "Группа до 15 человек",
        time: "c 18:30 до 21:00"

    }

    return (
        <div className="flex flex-col">
            <div className="flex mb-12">
                <button className="mr-3" onClick={goBack}>
                    Войти в IT
                </button>
                <p className="mr-3"> 	
                    &#60;
                </p>
                <button className="mr-3" onClick={goBack}>
                    Курсы
                </button>
                <p className="mr-3"> 	
                    &#60; 
                </p>
                <button>
                    {course.title} 
                </button>
            </div>
            <div className="flex border-b-2 border-[skyblue] justify-center items-center mb-10">
                <div className="mr-16">
                    <img src = {course.logo} alt="logo" className="w-[300px]"/>
                </div>
                <div className="flex flex-col">
                    <h2 className="font-bold text-3xl">
                        {course.title}
                    </h2>
                    <p className="mb-2 text-[skyblue] text-xl">
                        {course.location}
                    </p>
                    <p className="text-xl mb-2">
                        {course.price} {course.sale}
                    </p>
                    <p className="mb-2 text-xl">
                        {course.classes}
                    </p>
                    <p className="mb-2 text-xl pb-4">
                        {course.start}
                    </p>
                </div>
            </div>
            <div className="flex flex-colmb-10">
                <h3 className="mb-2 font-bold text-xl">
                    Курс ведут
                </h3>
            </div>
            <div className="flex justify-center mb-10">
                <img src={speaker} alt="speacer" className="mr-3 w-[110px]"/>
                <img src={speakerTwo} alt="speacer" className="w-[110px]"/>
            </div>
            <div className="mb-10">
                <h3 className="text-xl font-bold mb-4">
                    Кратко о курсе
                </h3>
                <p className="text-xl">
                    {course.preview}
                </p>
            </div>
            <div className="flex justify-around mb-10">
                <button className="border-2 border-skyblue rounded-md px-5 py-2 bg-skyblue text-white ">
                    Записаться
                </button>
                <button className="border-2 border-skyblue rounded-md px-5 py-2 bg-skyblue text-white ">
                    Контакты
                </button>
                <button className="border-2 border-skyblue rounded-md px-5 py-2 bg-skyblue text-white ">
                    В избронное
                </button>
            </div>
            <div>
                <h3 className="text-xl font-bold">
                    Подробно о курсе 
                </h3>
                <p>
                    Мастер-классы в рамках курса: 
                </p>
            </div>
        </div>
    );
};

export default DetailCourses;