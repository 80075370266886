const Tag = ({ text, isActive = null, clickHandler = null }) => {
  if (isActive) {
    return (
      <li className="border border-[#4985FF] rounded-full
                   h-[28px] bg-[#F2F6FF]
                   px-[10px] py-[5px]
                   text-[14px] font-semibold leading-[130%] text-[#4985FF]
                   list-none tracking-[-0.006em]
      " onClick={clickHandler}>
        { text }
      </li>
    )
  }
  return (
    <li className="border border-[#BFD4FF] rounded-full
                   h-[28px]
                   px-[10px] py-[5px]
                   text-[14px] font-semibold leading-[130%] text-[#808099]
                   list-none tracking-[-0.006em]
    " onClick={clickHandler}>
      { text }
    </li>
  )
}

export default Tag
