import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveAllCategories, setActiveCategory } from '../../../../../store/reducers/EnterItArticlesReducer'
import Tag from '../../Tag'
import SubcategoriesSelect from './SubcategoriesSelect'

function CategoriesList() {
  const [selectAll, setSelectAll] = useState(false)
  const categories = useSelector((state) => state.enterItArticles.categories)
  const dispatch = useDispatch()

  useEffect(() => {
    categories.map(category => category.isActive === false && setSelectAll(false))
    categories.every(category => category.isActive === true) && setSelectAll(true)
  }, [categories])

  const selectAllCategoriesHandler = () => {
    setSelectAll(!selectAll)
    dispatch(setActiveAllCategories(!selectAll))
  }
  const selectCategoryHandler = (category) => {
    const selectedCategory = {...category, isActive: !category.isActive}
    dispatch(setActiveCategory(selectedCategory))
  }

  return (
    <>
    <div>
      <div className="text-[14px] text-[#808099] font-semibold leading-[130%] h-[18px] mb-5">
        Категории
      </div>
      <div className="flex gap-[10px] flex-wrap">
        {categories.map((category) => (
          <div className="cursor-pointer" key={category.id}>
            <Tag
              text={category.label}
              isActive={category.isActive}
              clickHandler={()=> selectCategoryHandler(category)}
            />
          </div>
        ))}
        <div className="cursor-pointer">
          <Tag
            text={'Выбрать все'}
            isActive={selectAll}
            clickHandler={selectAllCategoriesHandler}
          />
        </div>
      </div>
    </div>
    <SubcategoriesSelect />
    </>
  )
}

export default CategoriesList
