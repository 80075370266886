import { useState } from "react"
import { NavLink } from 'react-router-dom'

const ArrowButton = ({ arrowImage, hoverImage, url }) => {
  const [over, setOver] = useState(false);

  return (
    <li className="h-3 w-3 my-[13px] flex flex-col justify-center">
      <NavLink to={url} className="w-[12px] h-[12px] py-[2px] pl-[3px] pr-1">
         <img src={over ? hoverImage : arrowImage} 
              onMouseOver={() => setOver(true)}
              onMouseOut={() => setOver(false)}
              alt={url}/>
      </NavLink>
    </li>
  )
} 

export default ArrowButton
