import React from "react";
import download from "../../assets/download-blue.png";
import print from "../../assets/print-blue.png";
import share from "../../assets/share-blue.png";
import bookmark from "../../assets/bookmark.png";
import { MenuShare } from "../MenuShare/MenuShare";

export const SidebarVacancy = ({chosenJob, handleBookmarkClick, bookmarked, handleShare, modalShare, handleDownload, handlePrint, closeMenuShare}) => {
  const shareUrl = window.location.href;

  return (
    <div className="absolute pr-11 right-0 flex flex-col items-center gap-5 text-darkSecondary h-60 lg:top-14 lg:top-28 lg:pr-7">
      <div className="group flex flex-col items-center gap-y-2 hover:cursor-pointer hover:text-buttonblue transition-all ">
        {/* <img
          src={bookmark}
          alt="Запомнить"
          className={`${bookmarked.includes(chosenJob.id) ? 'opacity-100' : 'opacity-30'} group-hover:opacity-100 transition-all`}
          onClick={() => handleBookmarkClick(chosenJob.id)}
        />
        <span className="lg:hidden">Запомнить</span> */}
      </div>
      <div onClick={handleDownload} className="group flex flex-col items-center gap-y-2 hover:cursor-pointer hover:text-buttonblue transition-all lg:hidden">
        <img src={download} alt="Скачать" className="opacity-30 group-hover:opacity-100 transition-all" />
        <span>Скачать</span>
      </div>
      <div onClick={handlePrint} className="group flex flex-col items-center gap-y-2 hover:cursor-pointer hover:text-buttonblue transition-all lg:hidden">
        <img src={print} alt="Распечатать" className="opacity-30 group-hover:opacity-100 transition-all" />
        <span>Распечатать</span>
      </div>
      <div onClick={handleShare} className="group flex flex-col items-center gap-y-2 hover:cursor-pointer hover:text-buttonblue transition-all">
        <img src={share} alt="Поделиться" className={`${modalShare ? 'opacity-100' : 'opacity-30'} group-hover:opacity-100 transition-all`} />
        <span className="lg:hidden">Поделиться</span>
      </div>
      {modalShare && <MenuShare url={shareUrl} title={'title'} modalShare={modalShare} closeMenuShare={closeMenuShare} />}
    </div>
  )
}