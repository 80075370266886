import React from 'react';
import Header from '../../common/Header/Header';
import Sidebar from '../../common/sidebar/Sidebar';
import filter from "../../assets/filter.png"
import InternshipsList from './InternshipsList';


const Internships = () => {
    return (
        <div>
            <Header headerContent={<h2 className='ml-[-43%]'>Стажировки</h2>} />
            <Sidebar/>
            <div className="flex justify-between text-xl shadow-bottom pb-3 mb-20">
                <div className="container mt-[100px] flex  ml-40">
                    <button className="border-2 border-skyblue rounded-md bg-skyblue px-5 py-2 text-white mr-4">
                       + Добавить стажировки
                    </button>
                </div>
                <div className="flex">
                    <div className="mr-4">
                        <label className="block text-sm font-medium text-gray">
                            Дата публикации
                        </label>
                        <select className="block py-2.5 px-0 w-full text-sm fill='none' bg-select bg-no-repeat bg-right border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                            <option value="3d">За последние 7 дней</option>
                            <option value="7d">За последний месяц</option>
                        </select>
                    </div>
                    <div>
                        <button  
                            className="border-2 border-skyblue rounded-lg mr-10 px-7 py-2 flex items-center mt-[100px] cursor-pointer z-40"
                        >

                            <img src={filter} className="mr-4" />
                            <p className="text-skyblue">
                                Фильтры
                            </p>
                         </button>
                    </div>  
                </div>
            </div>
            <div>
                <InternshipsList/>
            </div>
        </div>
    );
};

export default Internships;