import home from '../../assets/home.svg'
import internships from '../../assets/internships.png'
import job from '../../assets/job.svg'
import telegram from '../../assets/vector.svg'
import events from '../../assets/events.png'
import enterit from '../../assets/enterit.png'
import more from '../../assets/more.png'
import mentors from '../../assets/mentors.png'
import news from '../../assets/news.png'
import logoside from '../../assets/logoside.svg'
import { NavLink } from 'react-router-dom'
import { routes } from '../../routes/routes'
import './Sidebar.css'
import React from 'react'
import { useWindowSize } from '../../helpers/useWindowSize'
import { Link } from 'react-router-dom'

function Sidebar() {
	const [menuActive, setMenuActive] = React.useState(false)
	const { width = 0 } = useWindowSize()

	const navItems = [
		{ title: 'Главная', icon: home, link: routes.home },
		{ title: 'Вакансии', icon: job, link: routes.jobs },
		// { title: 'Стажировки', icon: internships, link: routes.internships }
		{ title: "Менторы", icon: mentors, link:routes.mentors },
		// { title: "Новости", icon: news, link:routes.home },
		// { title: "События", icon: events, link:routes.events },
		// { title: 'Войти в IT', icon: enterit, link: routes.courses },
		{ title: 'Телеграм', icon: telegram, link: routes.telegram }
	]

	return width > 600 ? (
		<div className='sidebar-container bg-midnight'>
			{/* <div className="sidebar flex flex-col h-screen p-3 shadow w-full m-0 p-0"> */}
			<div className='logo-container'>
			<Link to="/">
				<img
					alt='logo'
					src={logoside}
					className={'logo'}
				/>
			</Link>
			</div>
			<div className='navbar-container'>
				<ul className='items-navbar'>
					{navItems.map((navItem, index) => (
						<li
							className=' item'
							key={index}>
							<NavLink
								to={navItem.link}
								className='flex flex-col items-center r p-2'>
								<div>
									<img src={navItem.icon} />
								</div>
								<div className=''>{navItem.title}</div>
							</NavLink>
						</li>
					))}
				</ul>
			</div>
			<div className='justify-self-end mb-20 hover:opacity-75'>
				{/* <a href="#" className="flex flex-col items-center mt-10">
						<img src={more} />
						<span>Больше</span>
					</a> */}
			</div>
			{/* </div> */}
		</div>
	) : (
		<div className={'sidebar-mobile'}>
			<nav>
				<div
					className='burger-btn'
					onClick={() => setMenuActive(!menuActive)}></div>
			</nav>
			<div className='navbar-container'>
			{menuActive ? <div className='blur' onClick={() => setMenuActive(false)} /> : null}
				<div
					className={menuActive ? 'menu active' : 'menu'}
					onClick={() => setMenuActive(false)}>
					<div
						className='menu__content'
						onClick={e => e.stopPropagation()}>
						<ul className='items-navbar'>
							{navItems.map((navItem, index) => (
								<li
									className='item'
									key={index}>
									<NavLink
										to={navItem.link}
										className='flex flex-col items-center r p-2 '>
										<div>
											<img src={navItem.icon} />
										</div>
										<div className=''>{navItem.title}</div>
									</NavLink>
								</li>
							))}
						</ul>
						<div className='contacts'>
							<span>+ 375 (29) 333 66 55 </span>
							<span>it-coty@gmail.com</span>
							<span>© 2023. ООО «IT Coty»</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Sidebar
