import React from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/sidebar/Sidebar";
import { routes } from "../../routes/routes"
import { Link } from "react-router-dom";
import CompanyContacts from "./CompanyContacts";
function Documents() {
	return (
		<div className="grid grid-cols-12 grid-rows-12 h-screen gap-0 ">
			<Header headerContent={<h2 className="ml-[-31%]">Документы</h2>} />
			<Sidebar />
            <CompanyContacts/>
			<section className="col-start-6 row-start-3 col-end-11">
				<article className="text-[#4F4F70]">
					{" "}
					<p className=''>
						ITCOTY — крупнейшая платформа для предпринимателей и
						высококвалифицированных специалистов малых, средних и крупных
						компаний. Студенты и продвинутые школьники нас тоже читают, причем
						со всего мира.{" "}
					</p>
					<p className="py-5">
						{" "}
						Каждый день, то есть без выходных, у нас публикуются ключевые
						новости, мнения, обзоры и аналитика. Материалы пишут и сотрудники
						редакции, и пользователи платформы. Вы тоже можете поделиться опытом
						и рассказать о себе или своём проекте.{" "}
					</p>
					<p>
						{" "}
						На площадке работают сервисы по поиску работы и отслеживанию
						профессиональных мероприятий, лекций и вебинаров. Мы непрерывно
						развиваем инструменты коммуникации, которые облегчат вам поиск новых
						коллег, партнёров, инвесторов и просто приятелей по профессиональным
						интересам.
					</p>
				</article>
            <div className="leading-8 text-buttonblue mt-5">
				<Link to={routes.termsOfUse}><h3>Пользовательское соглашение</h3></Link>
				<Link to={routes.privacyPolicy}><h3>Политика конфиденциальности</h3></Link>
				<Link to={routes.publicOffer}><h3>Публичная оферта</h3></Link></div>
			</section>
		</div>
	);
}

export default Documents;
