import React from 'react'
import logo from '../../assets/logohead.svg'
import SearchInput from '../SearchInput/SearchInput'
import { Link } from 'react-router-dom'
import './Header.css'

function Header({ headerContent }) {
	return (
		<div className={'headerContainer'}>
      <div className='logohead'>
        {headerContent === '' ? (
          // Оборачиваем логотип в Link и указываем путь к главной странице
          <Link to="/">
            <img className='px-[63px]' src={logo} alt="Логотип" />
          </Link>
        ) : (
          headerContent
        )}
      </div>
			{/* <div className='header'> */}
			{/* <div className='search-input'>
					<SearchInput />
				</div>
				<div className='language'>
					<div className=''>RU</div>
					<div>
						<Link to='/registration'>
							<button
								className='signin
														w-[110px]
														py-3
														pl-5
														bg-buttonblue
														text-white
														font-medium
														text-[14px]
														rounded
														block 
														hover:shadow-lg
														focus:shadow-lg 
														active:shadow-lg
														transition
														duration-150
														ease-in-out
													'>
								Вход
							</button>
						</Link>
					</div>
				</div> */}
			{/* </div> */}
		</div>
	)
}

export default Header
