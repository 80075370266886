import React from 'react'

const BadgedButtonForMobile = ({ icon, counter, handler }) => {
  return (
    <button
      className="relative"
      onClick={handler}
    >
      <img src={icon} alt="icon" />
      {!counter ? (
        ''
      ) : (
        <div
          className="h-5 w-5
                absolute bottom-6 -right-3
                bg-[#F2994A] rounded-full
                border-white border-2
                flex justify-center items-center
                "
        >
          <div
            className="text-white text-[12px] font-semibold
                  w-[11px] h-[11px] lining-nums flex justify-center items-center top-1 absolute
                  "
          >
            {counter}
          </div>
        </div>
      )}
    </button>
  )
}

export default BadgedButtonForMobile
