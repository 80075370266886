import React from 'react'
import { Link } from 'react-router-dom'
import { dateFormat } from '../../helpers/dateFormat'
import { useWindowSize } from '../../helpers/useWindowSize'
import { routes } from '../../routes/routes'
import { formatSalary } from '../../helpers/salaryUtils'
import check from '../../assets/jobs/check.png';
import dash from '../../assets/jobs/dash.png';

export const HomePageVacancies = ({ newCommonVacancies, bookmark, showmore, changeRoute }) => {
	const { width = 0 } = useWindowSize()

	return width > 800 ? (
		<div className='containerHomePageVacancies pt-10'>
			<div className='flex justify-start'>
				<h2 className='pl-5 pr-10'>Популярные вакансии</h2>
				<Link
					to={routes.jobs}
					className='flex'>
					<p className='pt-2 text-buttonblue'>Все вакансии</p>
					<div className='showmore my-4 ml-2'>
						<img
							alt='showmore'
							src={showmore}
						/>
					</div>
				</Link>
			</div>

			<div className='jobs flex justify-around flex-wrap'>
				{newCommonVacancies?.map((job, index) => {
					let currentDate = new Date(job.created_at)
					currentDate.setHours(currentDate.getHours() - 3)

					return (
						<div
							id={`job${index}`}
							key={index}
							className='job w-[30%] px-5 h-[150px] flex flex-col my-10 relative'>
							<div className='flex flex-row justify-between items-center w-[100%] mb-5'>
								<div className='flex items-center'>
									{job.logo ? (
										<img
											alt='icon'
											className='h-7 mr-2'
											src={job.logo}
										/>
									) : (
										<img
											alt='icon'
											className='h-5 mr-2'
											src={'https://i.ibb.co/821D6sV/bullet.png'}
										/>
									)}
									<p className='title font-semibold text-gray mr-2'>{job.company}</p>
								</div>
								<p className='font-bold text-slate-400 h-[100%] flex items-center'>
									{dateFormat(currentDate)}
								</p>
							</div>

							<div className='flex flex-row justify-between items-center w-[100%] mb-5'>
								<div className='vacancyContainer flex items-center w-[55%] mr-1'>
									{/* <img
										alt='bookmark'
										src={bookmark}
										className='bookmark h-10 mr-2 opacity-50 hover:opacity-100'
									/> */}
									<div className='flex flex-col'>
										<h3 className='vacancy text-buttonblue tracking-tight'>{job.vacancy}</h3>
										<p className='mt-1 font-semibold'>{job.level}</p>
									</div>
								</div>
								<div className='flex flex-col items-end w-[35%] ml-5'>
									<h3 className='salary tracking-tight'>{formatSalary(job.salary)}</h3>
									<p className='text-gray'>{job.city ? job.city : 'no location'}</p>
								</div>
							</div>

							<div className='typeContainer flex flex-row justify-between items-center absolute bottom-0 right-5 left-6 h-5'>
								<span className="flex items-center gap-1">
									{job.job_type.includes('remote') ? (
										<span>remote</span>
									) : (
										<span></span>
									)}
								</span>
								<button
									type='button'
									onClick={() => changeRoute(job)}
									className='button 
													h-10
													w-[50%]
													text-buttonblue
													font-semibold
													text-[14px]
													rounded-lg
													border-2
													block
													hover:shadow-lg
													focus:shadow-lg
													active:shadow-lg
													transition
													duration-150
													ease-in-out
													'>
									Подробнее
								</button>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	) : (
		<div className='containerHomePageVacancies pt-10'>
			<h2 className='titleVacancies pl-5 pr-10'>{width < 490 ? 'Популярные вакансии' : 'Популярные вакансии'}</h2>

			<div className='jobs flex justify-around flex-wrap'>
				{newCommonVacancies?.map((job, index) => {
					let currentDate = new Date(job.created_at)
					currentDate.setHours(currentDate.getHours() - 3)

					return (
						<div
							id={`job${index}`}
							key={index}
							className='job w-[30%] px-5 h-[218px] flex flex-col my-10 relative'>
							<div className='flex flex-row justify-between items-center w-[100%] mb-5'>
								<div className='flex items-center'>
									{job.logo ? (
										<img
											alt='icon'
											className='h-7 mr-2'
											src={job.logo}
										/>
									) : (
										<img
											alt='icon'
											className='h-5 mr-2'
											src={'https://i.ibb.co/821D6sV/bullet.png'}
										/>
									)}
									<p className='title font-semibold text-gray mr-2'>{job.company}</p>
								</div>
								<p className='font-bold text-slate-400 h-[100%] flex items-center'>
									{dateFormat(currentDate)}
								</p>
							</div>

							<div className='flex flex-row justify-between items-center w-[100%] mb-5'>
								<div className='vacancyContainer flex items-center w-[55%] mr-1'>
									{/* <img
										alt='bookmark'
										src={bookmark}
										className='bookmark h-10 mr-2 opacity-50 hover:opacity-100'
									/> */}
									<div className='flex flex-col'>
										<h3 className='vacancy text-buttonblue tracking-tight'>{job.vacancy}</h3>
										<p className='mt-1 font-semibold'>{job.level}</p>
									</div>
								</div>
								<div className='flex flex-col items-end w-[35%] ml-5'>
									<h3 className='salary tracking-tight'>{formatSalary(job.salary)}</h3>
									<p className='text-gray'>{job.city ? job.city : 'no location'}</p>
								</div>
							</div>

							<div className='typeContainer flex flex-row justify-between items-center absolute bottom-0 right-5 left-6 h-5'>
							<span className="flex items-center gap-1">
								{job.job_type.includes('remote') ? (
									<span>remote</span>
								) : (
									<span></span>
								)}
							</span>
								<button
									type='button'
									onClick={changeRoute}
									className='button 
													px-6
													h-10
													w-[50%]
													text-buttonblue
													font-semibold
													text-1
													rounded-lg
													border-2
													block
													hover:shadow-lg
													focus:shadow-lg
													active:shadow-lg
													transition
													duration-150
													ease-in-out
													'>
									Подробнее
								</button>
							</div>
						</div>
					)
				})}
			</div>
			<Link
				to={routes.jobs}
				className='flex linkVacancies'>
				<p className='pt-2 text-buttonblue'>Все вакансии</p>
				<div className='showmore my-4 ml-2'>
					<img
						alt='showmore'
						src={showmore}
					/>
				</div>
			</Link>
		</div>
	)
}
