import { AuthorizationForm } from "./AuthorizationForm/AuthorizationForm";
import { RegAuthLayout } from "../RegAuthLayout";

export default function Authorization() {
  return (
    <RegAuthLayout>
      <AuthorizationForm />
    </RegAuthLayout>
  );
};
