import React from "react";
import mailru from "../../assets/mailru.png";
import mail from "../../assets/mail.png";
import google from "../../assets/google.png";

export const SocialLoginOptions = () => {
  return (
    <>
      <div className="flex items-center justify-between pt-10">
        <div className="h-px w-[70px] bg-gray"></div>
        <span className="flex-shrink w-[110px] text-gray mx-2 text-center font-semibold text-[14px] leading-[18.2px]">
          Или войти через
        </span>
        <div className="h-px w-[70px] bg-gray"></div>
      </div>
      <div className="flex justify-around p-5">
        <div>
          <img src={mailru} alt="mail.ru" />
        </div>
        <div className="my-auto">
          <img src={mail} alt="mail.ru" />
        </div>
        <div>
          <img src={google} alt="mail.ru" />
        </div>
      </div>
    </>
  );
};
