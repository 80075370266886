export function formatSalary(salary) {
  if (salary !== null && salary !== undefined) {
    const formattedSalary = salary.toString();
    const replacedSalary = formattedSalary.replace(/(\s*)000/g, "K");
    return replacedSalary;
  }
  return salary;
}
// export function formatSalary(salary) {
//   let result = '';

//   if (salary !== null && salary !== undefined) {
//     const replacedSalary = salary
//       .replace('от', '')
//       .replace(/,/g, '.')
//       .split('до')
//       .map(part => (parseFloat(part.replace(/\s+/g, '')) / 1000).toFixed(1) + 'K');
//     result = `от ${replacedSalary[0]} до ${replacedSalary[1]}`;
//   }

//   return result;
// }
