import React from 'react'
import cat from '../../assets/cat.svg'

export const TemporaryPage = () => {
	return (
		<div
			style={{
				alignItems: 'center',
				backgroundColor: '#fff',
				color: '#fff',
				display: 'flex',
				flexDirection: 'column',
				fontSize: 'calc(10px + 2vmin)',
				justifyContent: 'center',
				minHeight: '100vh',
				fontFamily:
					'-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif'
			}}>
			<img
				src={cat}
				alt='logo'
				className='h-[20vmin]'
			/>
			<div style={{ fontSize: '4vw', color: 'rgb(73, 133, 255)' }}>Cкоро открытие!</div>
			<div style={{ color: 'rgb(0, 0, 0)', marginTop: '20px' }}>
				Телеграм каналы с IT вакансиями:
			</div>
			<a
				style={{ color: '#61dafb', marginTop: '20px', textDecoration: 'underline' }}
				href='https://t.me/it_jobs_agregator'
				target='_blank'
				rel='noopener noreferrer'>
				Все IT вакансии (много)
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/jobs_for_it_juniors'
				target='_blank'
				rel='noopener noreferrer'>
				Для Junior специалистов
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/jobs_for_frontend'
				target='_blank'
				rel='noopener noreferrer'>
				Для Front-end разработчиков
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/backend_dev_jobs_agregator'
				target='_blank'
				rel='noopener noreferrer'>
				Для Back-end разработчиков
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/+Zwfw9SzOojI3ZjVi'
				target='_blank'
				rel='noopener noreferrer'>
				Для Mobile разработчиков
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/qa_job_agregator'
				target='_blank'
				rel='noopener noreferrer'>
				Для QA специалистов
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/Jobs_for_designers'
				target='_blank'
				rel='noopener noreferrer'>
				Для дизайнеров (UX/UI, 2&amp;3D, Motion...)
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/BA_jobs_agregator'
				target='_blank'
				rel='noopener noreferrer'>
				Для аналитиков (BA, Data...)
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/Jobs_aggregator_PM'
				target='_blank'
				rel='noopener noreferrer'>
				Для PM (Product and Project managers)
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/+q5GC-q4o6gYxMzFi'
				target='_blank'
				rel='noopener noreferrer'>
				Для Gamedev (Dev, designers...)
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/+QjwAiEylInNhMDEy'
				target='_blank'
				rel='noopener noreferrer'>
				Для DevOps
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/+-Jg3LAn5Wt45NmJi'
				target='_blank'
				rel='noopener noreferrer'>
				Для IT HR
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/+R3w01li8sUk2ZTBi'
				target='_blank'
				rel='noopener noreferrer'>
				Для IT sales
			</a>
			<a
				style={{ color: '#61dafb', marginTop: '15px', textDecoration: 'underline' }}
				href='https://t.me/+bDDQhL-19ERmMjky'
				target='_blank'
				rel='noopener noreferrer'>
				Для IT Marketeers
			</a>
		</div>
	)
}
