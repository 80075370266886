import React, { useState } from 'react';
import ModalCourses from '../../InIt/Courses/ModalCourses';
import js from '../../../assets/courses1.png';
import bookmark from '../../../assets/bookmark.png';
import share from '../../../assets/share.png';
import { NavLink } from 'react-router-dom';
import { routes } from '../../../routes/routes';

const Courses = () => {
    const [showModal, setShowModal] = useState(false);

  const courses = [
    {
      id: '1',
      logo: js,
      company: 'Geekbrains',
      title: 'JavaScrit для начинающих',
      description:
        'В данном курсе рассмотрены основы программирования на JavaScript а также некоторые инструменты и модели данных, необходимые для практического использования JavaScript.',
      location: 'Online',
      start: 'Старт в любое время',
      classes: '30 занятий',
      price: '120$',
      level: 'Начало карьеры',
      direction: 'Frontend',
    },
    {
      id: '2',
      logo: js,
      company: 'Geekbrains',
      title: 'JavaScrit для начинающих',
      description:
        'В данном курсе рассмотрены основы программирования на JavaScript а также некоторые инструменты и модели данных, необходимые для практического использования JavaScript.',
      location: 'Online',
      start: 'Старт в любое время',
      classes: '30 занятий',
      price: '120$',
      level: 'Начало карьеры',
      direction: 'Frontend',
    },
    {
      id: '3',
      logo: js,
      company: 'Geekbrains',
      title: 'JavaScrit для начинающих',
      description:
        'В данном курсе рассмотрены основы программирования на JavaScript а также некоторые инструменты и модели данных, необходимые для практического использования JavaScript.',
      location: 'Online',
      start: 'Старт в любое время',
      classes: '30 занятий',
      price: '120$',
      level: 'Начало карьеры',
      direction: 'Frontend',
    },
    {
      id: '4',
      logo: js,
      company: 'Geekbrains',
      title: 'JavaScrit для начинающих',
      description:
        'В данном курсе рассмотрены основы программирования на JavaScript а также некоторые инструменты и модели данных, необходимые для практического использования JavaScript.',
      location: 'Online',
      start: 'Старт в любое время',
      classes: '30 занятий',
      price: '120$',
      level: 'Начало карьеры',
      direction: 'Frontend',
    },
  ];
  return (
    <>
         {showModal ? <ModalCourses showModal={showModal} setShowModal={setShowModal}/> : null}
      {courses.map((course) => (
        <div key={course.id} className="flex mb-10 px-10 py-10 w-[1340px]">
          <div className="w-1/4 mr-20 flex flex-col items-center justify-start">
            <img src={course.logo} alt={course.title} className="mb-2"/>
            <p className=" text-gray">{course.company}</p>
          </div>
          <div className="w-1/2 flex flex-col mr-20">
            <h3 className="mb-5 font-bold text-2xl">{course.title}</h3>
            <p className="mb-7 text-gray">{course.description}</p>
            <div className="flex justify-between mb-7">
              <p className="mr-5 text-gray">{course.location}</p>
              <p className="mr-5 text-gray">{course.start}</p>
              <p className="mr-5 text-gray">{course.classes}</p>
              <p className="mr-5 text-gray">{course.price}</p>
            </div>
            <NavLink to={`/it/courses/${course.id}`} className="text-skyblue cursor-pointer mb-7 text-left">
              Подробнее
            </NavLink>
            <div className="flex">
              <p className="border-2 border-gray rounded-3xl px-[18px] py-[6px] text-gray mr-2">
                {course.level}
              </p>
              <p className="border-2 border-gray rounded-3xl px-[18px] py-[6px] text-gray">
                {course.direction}
              </p>
            </div>
          </div>
          <div className="w-1/7 flex items-start">
            <img
              src={bookmark}
              className="mr-5  opacity-20 hover:opacity-100"
            />
            <img src={share} />
          </div>
        </div>
      ))}
    </>
  );
};

export default Courses;
