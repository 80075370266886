import { NavLink } from 'react-router-dom'

const NumberButton = ({ pageNumber, isActive }) => {
  return (
    <li className={"h-[38px] w-[31px] py-[10px] px-[12px] hover:text-[#BFD4FF]"} >
      { pageNumber !== '...' 
        ? <NavLink to={`?page=${pageNumber}`} className={isActive && `text-[#4985FF] font-bold`}>{pageNumber}</NavLink>
        : <span className="cursor-default">...</span>
      }
    </li>
  )
}

export default NumberButton