import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { routes } from "../../../../routes/routes";
import { regValidSchema } from "./schema/validationSchema/validation";
import { regFormSchema } from "./schema/formSchema/formSchema";
import { SocialLoginOptions } from "../../SocialLoginOptions";
import { SuccessfulRegistration } from "./SuccessfulRegistration";
import { useWindowSize } from "../../../../helpers/useWindowSize";

export const RegistrationForm = () => {
  const { width: screenWidth } = useWindowSize();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(regValidSchema),
  });
  const [isRegistered, setIsRegistered] = React.useState(false);

  const onSubmit = (data) => {
    const reqData = { email: data.email, password: data.password };
    console.log(reqData);
    setIsRegistered(true)
  };
  return (
    <>
      <div
      className={`row-start-2 col-start-2 row-end-13 col-end-8 bg-main bg-cover bg-center ${
        screenWidth > 900 ? '' : 'hidden'
      }`}></div>
      <div className={`${screenWidth > 900 ? 'bg-white my-auto mx-auto row-start-2 col-start-8 row-end-13 col-end-13' 
      : screenWidth > 600 ? 'bg-white my-auto mx-auto row-start-2 col-start-2 row-end-13 col-end-13' 
      : 'bg-white my-auto mx-auto row-start-2 col-start-1 row-end-12 col-end-13'}`}>
        {isRegistered ? (
          <SuccessfulRegistration email={getValues("email")} />
        ) : (
        <form className="w-[300px]" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="pb-[16px] text-center font-semibold text-[14px] leading-[18.2px]">Создать аккаунт</h2>
          {regFormSchema.map((field) => (
            <div className="form-group mb-6" key={field.name}>
              <label className="form-label inline-block mb-2 text-sm text-gray">
                {field.label}
              </label>
              <input
                type={field.type}
                className={`form-control block w-full h-[44px] px-3 py-1.5 text-base font-normal bg-formgray bg-clip-padding border rounded transition ease-in-out m-0
                ${
                  isDirty && errors[field.name]
                    ? "border-red-500 text-red-500"
                    : "border-gray-300 text-gray-600"
                }
              `}
                id={field.name}
                placeholder={field.placeholder}
                {...register(field.name)}
                style={{ outline: "none" }}
              />
              {errors[field.name] && (
                <span className="error text-[14px] text-red-500 mt-1 font-semibold leading-[18.2px]">
                  {errors[field.name].message}
                </span>
              )}
            </div>
          ))}
          <div className="form-group form-check mb-6 mt-6 w-[300px]">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-check-input pr-3 w-[10px] h-[14px] appearance-none border border-gray rounded-sm bg-white checked:bg-blue checked:bg-skyblue focus:outline-none transition duration-200 cursor-pointer"
                id="conditions"
                {...register("conditions")}
              />
              <label className="form-check-label text-[14px] font-semibold leading-[18.2px] ml-3">
                Подтверждаю, что ознакомлен, полностью согласен и принимаю условия{" "}
                <Link to={routes.termsOfUse} className="text-blue-600">
                  Пользовательского соглашения
                </Link>
              </label>
            </div>
            {errors?.conditions && (
              <span className="error flex text-[14px] text-red-500 mt-1 font-semibold leading-[18.2px]">
                {errors.conditions.message}
              </span>
            )}
          </div>
          <button
            type="submit"
            disabled={!isValid}
            className={`px-6 py-2.5 bg-buttonblue text-white font-semibold text-14px leading-[18.2px] h-[44px] w-full rounded block hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer`}
          >
            Зарегистрироваться
          </button>
  
          <p className="text-center text-gray p-3 font-semibold text-0.875rem leading-1.1375rem">
            Уже есть аккаунт?
          </p>
          <Link to={routes.authorization}>
            <button
              type="button"
              className={`px-6 py-2.5 text-buttonblue font-semibold text-14px leading-[18.2px] h-[44px] rounded border block  w-full hover:shadow-lg focus:shadow-lg  active:shadow-lg transition duration-150 ease-in-out`}
            >
              Войти
            </button>
          </Link>
          <SocialLoginOptions />
          </form>
      )}
    </div>
    </>
);
};
