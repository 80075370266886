import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveAllFilters, setActiveFilter } from '../../../../../store/reducers/EnterItArticlesReducer'

import Tag from '../../Tag'

const FiltersTagedList = () => {
  const [selectAll, setSelectAll] = useState(false)
  const filters = useSelector(state => state.enterItArticles.filters)
  const dispatch = useDispatch()

  useEffect(() => {
    filters.map(filter => filter.isActive === false && setSelectAll(false))
    filters.every(filter => filter.isActive === true) && setSelectAll(true)
  }, [filters])

  const selectAllFiltersHandler = () => {
    setSelectAll(!selectAll)
    dispatch(setActiveAllFilters(!selectAll))
  }

  const selectFilterHandler = (filter) => {
    const selectedFilter = {...filter, isActive: !filter.isActive}
    dispatch(setActiveFilter(selectedFilter))
  }


  return (
    <div className="flex gap-[10px] flex-wrap">
      {
        filters.map(filter => (
          <div className="cursor-pointer" key={filter.id}>
            <Tag text={filter.label} isActive={filter.isActive} clickHandler={() => selectFilterHandler(filter)}/>
          </div>
        ))
      }
      <div className="cursor-pointer">
        <Tag text={'Выбрать все'} isActive={selectAll} clickHandler={selectAllFiltersHandler}/>
      </div>
    </div>
  )
}

export default FiltersTagedList