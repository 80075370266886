import React from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/sidebar/Sidebar";

export const RegAuthLayout = ({ children }) => {
  return (
    <div className="grid grid-cols-12 grid-rows-12 h-screen gap-0">
      <div className="row-start-1 col-start-2 col-end-13 sm:col-start-2 sm:col-end-13">
        <Header headerContent={""} />
      </div>
      <Sidebar />
      {children}
    </div>
  );
};
