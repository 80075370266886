
const development = [
  { value: "python", label: "Python" },       /* Backend */
  { value: "c", label: "C" },
  { value: "php", label: "PHP" },
  { value: "java", label: "Java" },
  { value: "ruby", label: "Ruby" },
  { value: "scala", label: "Scala" },
  { value: "net", label: ".NET" },
  { value: "nodejs", label: "Node.js" },
  { value: "laravel", label: "Laravel" },
  { value: "golang", label: "Golang" },
  { value: "delphi", label: "Delphi" },
  { value: "abap", label: "ABAP" },
  { value: "ml", label: "Machine Learning" },
  { value: "data_engineer", label: "Data science" },
  { value: "unity", label: "Unity" },
  { value: "one_c", label: "1C" },
  { value: "embedded", label: "Embedded" },

  { value: "vue", label: "Vue.js" },       /* Frontend */
  { value: "react", label: "React" },
  { value: "angular", label: "Angular" },
  { value: "wordpress", label: "Wordpress" },
  { value: "bitrix", label: "Bitrix" },
  { value: "joomla", label: "Joomla" },
  { value: "drupal", label: "Drupal" },

  { value: "ios", label: "iOS" },        /* Mobile */
  { value: "android", label: "Android" },
  { value: "cross_mobile", label: "Cross-platform" },
  { value: "flutter", label: "Flutter" },
  { value: "react_native", label: "React Native" },
];

const design = [
  { value: "dd", label: "2D design" },
  { value: "ddd", label: "3D design" },
  { value: "game_designer", label: "Gamedev design" },
  { value: "motion", label: "Motion" },
  { value: "ui_ux", label: "UX/UI design" },
  { value: "graphic", label: "Графический дизайн" },
  { value: "illustrator", label: "Иллюстрация" },
  { value: "uxre_searcher", label: "UX-исследователь" },
];

const devOps = [
  { value: "devops", label: "DevOps", },
  { value: "admins}", label: "Системный администратор", },
]

const hr = [
  {value: "hr", label: "HR"},
  {value: "recruter", label: "Рекрутинг"},
]

const marketing = [
  {value: "smm", label: "SMM / Community"},
  {value: "copyrighter", label: "Копирайтер"},
  {value: "seo", label: "SEO"},
  {value: "link_builder", label: "Link Builder"},
  {value: "media_buyer", label: "Media Buyer"},
  {value: "email_marketer", label: "E-mail (Direct)"},
  {value: "context", label: "Context"},
  {value: "content_manager", label: "Контент менеджер"},
  {value: "tech_writer", label: "Технический писатель"},
];

const qa = [
  {value: "aqa", label: "Auto"},
  {value: "manual_qa", label: "Manual"},
  {value: "support", label: "Техническая поддержка"},
];

const sales = [
  {value: "Аккаунтинг", label: "Аккаунтинг"},
  {value: "Биздев", label: "Биздев"},
  {value: "Клиентская поддержка", label: "Клиентская поддержка"},
  {value: "Привлечение клиентов", label: "Привлечение клиентов"},
  {value: "Работа с ключевыми клиентами", label: "Работа с ключевыми клиентами"},
  {value: "Работа с партнерами", label: "Работа с партнерами"}
];

const projectManagement = [
  {value: "product", label: "Product management"},
  {value: "project", label: "Project management"},
];

const analytics = [
  {value: "data_scientist", label: "Data science"},
  {value: "data_analyst", label: "Data Analyst"},
  {value: "ba", label: "Бизнес анализ"},
  {value: "sys_analyst", label: "Системный анализ"},
];

const game = [
  {value: "unity", label: "Unity"},
  {value: "ue", label: "Unreal Engine"},
];

const noCode = [
  {value: "tilda", label: "Tilda"},
  {value: "bubble", label: "Bubble"},
  {value: "wordpress", label: "Wordpress"},
];

export const profession = [
  { value: "devops", label: "DevOps", specialization: devOps },
  { value: "hr", label: "HR", specialization: hr },
  { value: "marketing", label: "Маркетинг", specialization: marketing },
  { value: "qa", label: "QA", specialization: qa},
  { value: "analyst", label: "Аналитика и данные", specialization: analytics },
  { value: "designer", label: "Дизайн", specialization: design },
  { value: "sales_manager", label: "Продажи и поддержка клиентов", specialization: sales },
  { value: "development", label: "Разработка/Программирование", specialization: development },
  { value: "pm", label: "Управление продуктом и проектами", specialization: projectManagement },
  {value: "game", label: "Gamedev", specialization: game},
  {value: "non_code_manager", label: "No-Code", specialization: noCode},
];

