import React from 'react'
import { useWindowSize } from '../../helpers/useWindowSize'

export const HomePageInfo = ({ selectCourse }) => {
	const { width = 0 } = useWindowSize()

	const handleOnClickBanner = () => {
		window.location.href = 'https://mvp.by/'
	}

	return width > 699 ? (
		<div className='news flex justify-around flex-wrap'>
			{/* <HomePageNews showmore={showmore} /> */}
			<div
				onClick={() => handleOnClickBanner()}
				className='flex mt-16 rounded-xl h-[90px] w-[728px] relative bg-zinc-50 banner mb-10'>
				<div className='rounded-xl imageBanner left-0'>
					<div className='logoBanner' />
					<h1 className='titleBanner text-sky-900	left-2 bottom-2 text-xs font-bold absolute'>
						MVP Connect
					</h1>
				</div>
				<div className='description flex flex-col relative'>
					<div className='text-slate-600 font-bold pt-4'>
						IT - курсы и практика на коммерческих проектах и стартапах
					</div>
					<div
						to={'https://mvp.by/'}
						className='linkCourse flex w-[26%] absolute bottom-2 right-0'>
						<p className='pt-2 text-buttonblue'>Выбрать курс</p>
						<div className='my-4 ml-2'>
							<img
								alt='showmore'
								src={selectCourse}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div className='news justify-around flex'>
			{/* <HomePageNews showmore={showmore} /> */}
			<div
				className='flex mt-16 rounded-xl relative bg-zinc-50 banner mb-10'
				onClick={() => handleOnClickBanner()}>
				<div className='description flex flex-col relative'>
					<div className='text-slate-600 font-bold pt-4'>
						IT - курсы и практика на коммерческих проектах и стартапах
					</div>
					<div
						to={'https://mvp.by/'}
						className='linkCourse flex w-[26%] absolute bottom-2 right-0'>
						<p className='pt-2 text-buttonblue'>Выбрать курс</p>
						<div className='my-4 ml-2'>
							<img
								alt='showmore'
								src={selectCourse}
							/>
						</div>
					</div>
				</div>
				<div className='rounded-xl imageBanner left-0'>
					<div className='logoBanner' />
					<h1 className='titleBanner text-sky-900	left-2 bottom-2 text-xs font-bold absolute'>
						MVP Connect
					</h1>
				</div>
			</div>
		</div>
	)
}
