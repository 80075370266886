import React from "react";

function Select({label, options, defaultValue}) {
	return (
		<div className="my-5">
			<label
				htmlFor="countries"
				className="block text-sm font-medium text-gray dark:text-gray"
			>
				{label}
			</label>
			<select
            defaultValue={defaultValue}
				id="countries"
				className=" border border-gray text-sm rounded-lg focus:ring-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
			> 
            <option key='-1'>{defaultValue}</option>
				{options.map((option, i)=>
                    <option key={i} value={option.name}>{option.name}</option>
                )}
			</select>
		</div>
	);
}

export default Select;
