import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    userData: {},
};

const UserSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setUserData(state, action) {
            state.userData = action.payload;
        }
    }
});

export const UserReducer = UserSlice.reducer;

// * Action creators

export const {setUserData} = UserSlice.actions;

// * Thunks Creators
