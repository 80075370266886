import React from "react";
import logo from "../../assets/logohead.png";
function CompanyContacts() {
	return (
		<div className="grid col-start-2 row-start-2 row-end-13 col-end-5">
			<div className="row-start-2 px-24 leading-8">
				<img src={logo} className="mb-5"/>
                <p>it-coty@gmail.com</p>
                <p>+ 375 (29) 333 66 55 </p>
			</div>
			<section className="text-sm text-[#808099] pl-24 pr-4 row-start-6">
				<p className="py-5">
					ООО «IT Coty» Зарегистрировано Минским горисполкомом 09.07.2020 220089
					г.Минск, пр-т Дзержинского, 57, 10 этаж, помещение 45-1, комната 2.
				</p>
				<p>
					Режим работы предприятия: пн.-пт. 09.00-18.00, вых. дн. – сб., вс.
				</p>
				<p className="py-5">
					Режим работы сайта – круглосуточно. E-mail ООО «ВСЯ РАБОТА»
					support@rabota.by
				</p>
				<p>
					р/с BY51ALFA30122667720010270000 ЗАО "Альфа-банк" 220013 г. Минск, ул.
					Сурганова, 43‑47
				</p>
			</section>
		</div>
	);
}

export default CompanyContacts;
