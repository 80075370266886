import React from 'react';

const ModalEvents = () => {
    return (
        
			<div className="no-scrollbar flex justify-end backdrop-blur-sm overflow-y-auto mt-[3%] fixed inset-0 z-30 outline-none focus:outline-none">
                <div className="relative w-1/2">
                    <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                    <p>Events</p>
                    </div>
                </div>
            </div>
    );
};

export default ModalEvents;