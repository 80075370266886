import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/sidebar/Sidebar";
import epam from "../../assets/epam.png";
import Modal from "./Modal/Modal";
import VacanciesList from "./VacanciesList";
import JobDetails from "./JobDetails";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { clearCompanySize, clearCompanyType, clearJobType, clearLevel, clearSalaryOption, clearTypeOfEmployment, filterVacancies, isClearFiltersReducer, removeAllVacancies, setCities, setCompanyScope, setCountries, setCurrency, setDirection, setSalaryFrom, setSalaryOption, setSalaryTo, setSpecialization, getVacancies, setChosenJob, getVacancyById, setLastVacancyID, setFilters, getVacanciesScroll } from "../../store/reducers/VacanciesReducer";
import { clearCheckbox } from "../../helpers/clearCheckbox";
import { useWindowSize } from "../../helpers/useWindowSize";
import { Link } from "react-router-dom";
import logo from '../../assets/logohead.svg'

function Jobs() {

	const internships = [
		{
			id: "1",
			company: "Wargaming",
			logo: epam,
			rating: 5.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "2",
			company: "Amazon",
			logo: epam,
			rating: 4.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "3",
			company: "Google",
			logo: epam,
			rating: 5.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "4",
			company: "Retarcorp",
			logo: epam,
			rating: 4.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "5",
			company: "Wargaming",
			logo: epam,
			rating: 4.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "6",
			company: "Wargaming",
			logo: epam,
			rating: 4.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "7",
			company: "Wargaming",
			logo: epam,
			rating: 4.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "8",
			company: "Wargaming",
			logo: epam,
			rating: 4.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "9",
			company: "Wargaming",
			logo: epam,
			rating: 4.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
		{
			id: "10",
			company: "Wargaming",
			logo: epam,
			rating: 4.0,
			position: "Backend Developer",
			published: "Сегодня 10.00",
			salary: "$800-$12K",
			location: "Minsk, BY",
			level: "Trainee",
			jobType: "Part-time, Office",
			companyDescription:
				"EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
			duties: "Разработка клиентских приложений на основе JavaScript.",
			requirements: [
				"Практический опыт работы с HTML5/CSS3",
				"Понимание принципов ООП, MVC и других JS-паттернов",
				"Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
				"Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
			],
			desirable: [
				"Знание и опыт разработки JavaScript от 1 года",
				"Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
				"Знания и опыт разработки Unit-тестов на JS-фреймворках",
				"Знания и опыт БЭМ методологии",
				"Знание английского языка на уровне Intermediate",
			],
			conditions: [
				"График работы – гибкий, от 20 часов в неделю",
				"Офис или удаленная работа, по желанию",
				"Зарплата в зависимости от уровня ваших умений",
				"Работа по Scrum",
			],
		},
	];

	//const [chosenJob, setChosenJob] = useState('');
	const [idVacancy, setIdVacancy] = useState(null);
	const location = useLocation();
	const { width: screenWidth } = useWindowSize();

// 	useEffect(() => {
//     const lastSegment = location.pathname.split('/').pop();
//     if (lastSegment && lastSegment !== 'jobs') {
//         setIdVacancy(lastSegment);
//     }
// }, [location.pathname]);

	useEffect(() => {
    const lastSegment = location.pathname.split('/').pop();
      setIdVacancy(lastSegment);
}, [location.pathname]);
	
	const [isJob, setIsJob] = useState(true);
	const { allVacancies, isPending, chosenJob, filters, amount, lastVacancyID } = useSelector((state) => state.vacancies);
	const vacancyRef = useRef();
	const dispatch = useDispatch();
	const [bookmarked, setBookmarked] = useState(localStorage.getItem('bookmarked') ? JSON.parse(localStorage.getItem('bookmarked')) : []);
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	const isHidden = true;
	const refForScroll = useRef();

	const handleBookmarkClick = (id) => {
		const copyBookmarked = Object.assign([], bookmarked);
		if (copyBookmarked.includes(id)) {
			const index = copyBookmarked.indexOf(id);
			copyBookmarked.splice(index, 1);
    } else {
			copyBookmarked.push(id);
		}
		setBookmarked(() => copyBookmarked);
		localStorage.setItem('bookmarked', JSON.stringify(copyBookmarked));
	}

	const updateShowModal = (value) => {
		setShowModal(value);
	};

	// useEffect(() => {
	// 	!allVacancies.length && dispatch(getVacancies({lastVacancyID: null, filters: null}))
	// }, [])

	useEffect(() => {
    if (!allVacancies.length || allVacancies.length === 1) {
        dispatch(getVacancies({ lastVacancyID: null, filters: null }));
    }
}, [allVacancies.length, dispatch]);

	useEffect(() => {
		if (idVacancy === 'jobs' && allVacancies.length && window.innerWidth > 1023 && Object.keys(chosenJob).length === 0) {
			dispatch(setChosenJob({}))
			dispatch(getVacancyById(allVacancies[0].id))
		}
	}, [allVacancies, idVacancy, dispatch]);

useEffect(() => {
	if (idVacancy !== null && idVacancy !== 'jobs') {
		dispatch(getVacancyById(idVacancy));
	}
}, [dispatch, idVacancy]);


	const onClickVacancy = (jobs, id) => {
		dispatch(getVacancyById(id))
	};

	const scrollHandler = () => {
    if (
        (refForScroll.current.scrollHeight - refForScroll.current.scrollTop - refForScroll.current.clientHeight) <= 750 &&
        allVacancies.length < amount
    ) {
        if (filters) {
            dispatch(getVacanciesScroll({
                lastVacancyID: null,
                filters: { ...filters, id: allVacancies[allVacancies.length - 1].id }
            }));
        } else {
            dispatch(getVacanciesScroll({
                lastVacancyID: allVacancies.length ? allVacancies[allVacancies.length - 1].id : null,
                filters: null
            }));
        }
    }
	}


	return (
		<div className={`grid grid-cols-12 grid-rows-12 h-screen gap-0 font-semibold text-sm text-gray sm:grid-rows-12-mobile ${screenWidth > 1023 ? 'overflow-hidden' : ''} ${showModal && 'overflow-hidden'}`}>
			<Sidebar />
			<div className={`grid grid-cols-11 grid-rows-11 row-start-1 col-start-2 col-end-13 row-end-13 ${screenWidth > 1023 ? 'overflow-hidden' : ''} relative gap-0 sm:col-start-1 sm:grid-rows-11-mobile`}>
				{/* <div className="row-start-1 row-end-2 col-start-1 col-end-12 sm:col-start-1">
					<Header headerContent={window.innerWidth > 600 ? <h2 className='text-base font-medium text-dark leading-[150%]'>Вакансии</h2> : ''} />
				</div> */}
				<div className="h-[63px] row-start-1 row-end-1 col-start-1 col-end-13 flex justify-between shadow-bottom sm:block sm:col-start-1 sm:row-end-1 sm:shadow-none">
					<div className="justify-between self-center ml-11 sm:hidden">
						<div className="mb-4">
							<ul
								className="flex"
								id="myTab"
								data-tabs-toggle="#myTabContent"
								role="tablist"
							>
								<li className="">
									<button
										className={`pt-4 rounded-t-lg mr-9 ${isJob?'border-b-2 border-buttonblue text-dark' : ''}`}
										id="profile-tab"
										data-tabs-target="#profile"
										type="button"
										role="tab"
										aria-controls="profile"
										aria-selected="false"
										onClick={() => setIsJob(true)}
									>
										Работа
									</button>
								</li>
								{!isHidden && <li className="mr-2" role="presentation">
									<button
										className={`pt-4 rounded-t-lg  ${!isJob?'border-b-2 border-buttonblue text-dark' : ''}`}
										id="dashboard-tab"
										data-tabs-target="#dashboard"
										type="button"
										role="tab"
										aria-controls="dashboard"
										aria-selected="false"
										onClick={() => setIsJob(false)}
									>
										Стажировка
									</button>
								</li>}
							</ul>
						</div>
					</div>
					<div className=" w-10/12 flex justify-end mx-5 gap-x-8 sm:w-full sm:mx-0 sm:justify-end bg-white">
					<Link className="absolute hidden w-[63px] h-[63px] sm:flex sm:justify-center sm:items-center mx-5 gap-x-8 sm:w-full sm:mx-0 bg-white" to="/">
            <img src={logo} alt="Логотип" />
          </Link>
						<div className="w-10/12 flex justify-end mx-5 gap-x-8 hidden">
							<div className="w-1/5">
								<label
									htmlFor="salary_select"
									className="block font-medium text-lightGray"
								>
									Зарплата
								</label>
								<select
									defaultValue="desc"
									id="salary_select"
									className="block py-2 px-0 w-full text-sm fill='none' bg-select bg-no-repeat bg-right border-b border-skyblue appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
								>
									<option value="desc">по убыванию</option>
									<option value="asc">по возрастанию</option>
								</select>
							</div>
							<div className="w-1/5 ">
								{" "}
								<label
									htmlFor="salary_select"
									className="block text-sm font-medium text-lightGray"
								>
									Дата публикации
								</label>
								<select
									id="salary_select"
									defaultValue="week"
									className="block py-2 px-0 w-full text-sm fill='none' bg-select bg-no-repeat bg-right border-b border-skyblue appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
								>
									<option value="week">за последние 7 дней</option>
									<option value="month">за последний месяц</option>
								</select>
							</div>
							<div className="w-1/5 ">
								{" "}
								<label
									htmlFor="salary_select"
									className="block text-sm font-medium text-lightGray"
								>
									Диапазон ввода
								</label>
								<select
									id="salary_select"
									defaultValue="3d"
									className="block py-2 px-0 w-full text-sm fill='none' bg-select bg-no-repeat bg-right border-b border-skyblue appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
								>
									<option value="3d">за 3 дня</option>
									<option value="7d">за 7 дней</option>
								</select>
							</div>
						</div>
						<div className={`${Object.keys(chosenJob).length !== 0 && 'lg:hidden'} self-center`}>
							<Modal showModal={showModal} updateShowModal={updateShowModal} />
							{showModal && <button
								className={`hidden bg-buttonblue text-white text-sm font-semibold rounded-md border w-[264px] h-[41px] md:block md:w-[343px] md:h-[44px] md:z-50 md:fixed md:left-[calc(50%-171px)] md:bottom-0`}
								onClick={() => setShowModal(false)}
							>
								Показать все {amount} вакансий
							</button>}
						</div>
					</div>
				</div>
				<div className={`row-start-2 row-end-13 col-start-1 overflow-auto overflow-x-hidden w-[40vw] sm:row-start-1 sm:mt-[65px] flex flex-col justify-start items-center gap-y-3 lg:w-full  lg:col-end-13 sm:col-start-1 ${Object.keys(chosenJob).length !== 0  ? 'lg:hidden' : 'lg:flex'}`} ref={refForScroll} onScroll={scrollHandler}>
				{isJob
					? (allVacancies.length > 0
						? <VacanciesList chosenJob={chosenJob} onClickVacancy={onClickVacancy} jobs={allVacancies} handleBookmarkClick={handleBookmarkClick} bookmarked={bookmarked}/>
						: <div>Загрузка ...</div>
					)
					: <VacanciesList chosenJob={chosenJob} setChosenJob={setChosenJob} jobs={internships}/>
				}
				</div>
				{Object.keys(chosenJob).length !== 0 && <JobDetails chosenJob={chosenJob} vacancyRef={vacancyRef} setChosenJob={setChosenJob} handleBookmarkClick={handleBookmarkClick} bookmarked={bookmarked} />}
			</div>
		</div>
	);
}

export default Jobs;
