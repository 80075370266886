import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/AuthService";

export const initialState = {
	isRegistred: false,
	isPending: false,
	registrationError: "",
};

export const registration = createAsyncThunk
("registration/register", async (formValue, thunkAPI) => {
		const { rejectWithValue } = thunkAPI;
		try {
			const res = await AuthService.registration(
				formValue.email,
				formValue.password,
			);
		localStorage.setItem('token', res.data.accessToken);
		console.log(formValue);
			return { userData: res.data };
		} catch (err) {
			return rejectWithValue({ errorMessage: err.response.data.error });
		}
	}
);
const registrationSlice = createSlice({
	name: "registration",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(registration.fulfilled, (state) => {
			state.isRegistred = true;
			state.isPending = false;
			state.registrationError = "";
		});
		builder.addCase(registration.rejected, (state, action) => {
			state.isRegistred = false;
			state.isPending = false;
			state.registrationError = action.payload?.errorMessage;
		});
		builder.addCase(registration.pending, (state) => {
			state.isPending = true;
		});
	},
});
export const registrationReducer = registrationSlice.reducer;