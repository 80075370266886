import React from 'react';
import logo from '../../assets/ITechArt.png'
import bookmark from '../../assets/bookmark.png'
import share from '../../assets/share.png'


const InternshipsList = () => {
    const internships = [
        {
            id: "1",
            logo: logo,
            company: "ITechArt",
            title: "Стажировка Junior DevOps Engineer",
            description: "Стажировка моделирует условия, максимально приближенные к реальному проекту. На 3 месяца ты становишься частью команды и разрабатываешь полноценный проект, всё это время тебя поддерживает опытный ментор.",
            location: 'Online',
            start: "Подача заявки до 31.03.22",
            classes: '2 месяца',
            price: 'Бесплатно',
            level: 'Начало карьеры',
            direction: 'Frontend',
        },
        {
            id: "2",
            logo: logo,
            company: "ITechArt",
            title: "Стажировка Junior DevOps Engineer",
            description: "Стажировка моделирует условия, максимально приближенные к реальному проекту. На 3 месяца ты становишься частью команды и разрабатываешь полноценный проект, всё это время тебя поддерживает опытный ментор.",
            location: 'Online',
            start: "Подача заявки до 31.03.22",
            classes: '2 месяца',
            price: 'Бесплатно',
            level: 'Начало карьеры',
            direction: 'Frontend',
        },
        {
            id: "3",
            logo: logo,
            company: "ITechArt",
            title: "Стажировка Junior DevOps Engineer",
            description: "Стажировка моделирует условия, максимально приближенные к реальному проекту. На 3 месяца ты становишься частью команды и разрабатываешь полноценный проект, всё это время тебя поддерживает опытный ментор.",
            location: 'Online',
            start: "Подача заявки до 31.03.22",
            classes: '2 месяца',
            price: 'Бесплатно',
            level: 'Начало карьеры',
            direction: 'Frontend',
        },
        {
            id: "4",
            logo: logo,
            company: "ITechArt",
            title: "Стажировка Junior DevOps Engineer",
            description: "Стажировка моделирует условия, максимально приближенные к реальному проекту. На 3 месяца ты становишься частью команды и разрабатываешь полноценный проект, всё это время тебя поддерживает опытный ментор.",
            location: 'Online',
            start: "Подача заявки до 31.03.22",
            classes: '2 месяца',
            price: 'Бесплатно',
            level: 'Начало карьеры',
            direction: 'Frontend',
        },
    ]

    return (
        <div className="container mx-auto w-[45vw] h-[1200px] flex flex-col items-center">
            {internships.map(internship => (
                <div key={internship.id} className="flex mb-10 px-10 py-10 w-[1340px]">
          <div className="w-1/4 mr-20 flex flex-col items-center justify-start">
            <img src={internship.logo} alt={internship.company} className="mb-2"/>
            <p className=" text-gray">{internship.company}</p>
          </div>
          <div className="w-1/2 flex flex-col mr-20">
            <h3 className="mb-5 font-bold text-2xl">{internship.title}</h3>
            <p className="mb-7 text-gray">{internship.description}</p>
            <div className="flex justify-between mb-7">
              <p className="mr-5 text-gray">{internship.location}</p>
              <p className="mr-5 text-gray">{internship.start}</p>
              <p className="mr-5 text-gray">{internship.classes}</p>
              <p className="mr-5 text-gray">{internship.price}</p>
            </div>
            <button className="text-skyblue cursor-pointer mb-7 text-left">
              Подробнее
            </button>
            <div className="flex">
              <p className="border-2 border-gray rounded-3xl px-[18px] py-[6px] text-gray mr-2">
                {internship.level}
              </p>
              <p className="border-2 border-gray rounded-3xl px-[18px] py-[6px] text-gray">
                {internship.direction}
              </p>
            </div>
          </div>
          <div className="w-1/7 flex items-start">
            <img
              src={bookmark}
              className="mr-5  opacity-20 hover:opacity-100"
            />
            <img src={share} />
          </div>
        </div>
            ))}
        </div>
    );
};

export default InternshipsList;