import React, { useRef, useState } from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/sidebar/Sidebar";
import "./Telegram.css";
import Send from '../../assets/vector2.svg'
import Junior from '../../assets/cat_avatar_junior.png'
import BackEnd from '../../assets/cat_avatar_back-end.png'
import DevOps from '../../assets/cat_avatar_devops.png'
import Designers from '../../assets/cat_avatar_designers.png'
import MobileDev from '../../assets/cat_avatar_mobile-dev.png'
import HR from '../../assets/cat_avatar_hr.png'
import QA from '../../assets/cat_avatar_qa.png'
import Sales from '../../assets/cat_avatar_sales.png'
import Gamedev from '../../assets/cat_avatar_gamedev.png'
import PM_PrdM from '../../assets/cat_avatar_PM_PrdM.png'
import Frontend from '../../assets/cat_avatar_frontend.png'
import ITBisnesAnalitic from '../../assets/cat_avatar_IT_analitic.png'
import Marketologs from '../../assets/cat_avatar_marketologs.png'
import Aggregator from '../../assets/cat_avatar_aggregator.png'
import Search from '../../assets/ic_Search.svg'
import Login from '../../assets/ic_LogIn.svg'
import Vector from '../../assets/vector-mobil.svg'
import Vector_card from '../../assets/vector_card_mobile.svg'
import { useWindowSize } from "../../helpers/useWindowSize";


// import epam from "../../assets/epam.png";
// import Modal from "./Modal/Modal";
// import VacanciesList from "./VacanciesList";
// import JobDetails from "./JobDetails";
// import { useSelector } from "react-redux";

const Telegram = () => {
    const { width: screenWidth } = useWindowSize();
    return (
        <div class='homePageContainer'>
            <Sidebar />

            <div class="mainContentContainer">
                {screenWidth <= 952 && <Header headerContent={''} className={''} />}
                {screenWidth > 952 ? (
                    <div id="mainContent_telegram" className="row-start-2 col-start-2 col-end-13 row-end-13 h-[63px] flex">
                            <h2 className='mb-[0] send flex justify-center items-center ml-[10px]'>Рекомендуем наши&nbsp;<span>Tелеграм каналы</span>&nbsp;с вакансиями и стажировками:</h2>
                            <img className='w-[30px] h-auto flex justify-center items-center ml-[10px]' src={Send} alt="вектор" />
                    </div>
                ) : null}
                <div id='container' className="row-start-4 col-start-2 col-end-13 row-end-12 flex">

                    <div className="telegram_cards">

                        <div className="telegram_card item1">
                            <img src={Junior} alt="аватар" />
                            <div className="item-title item1">
                                <a href="https://t.me/jobs_for_it_juniors"><p className='name_UPP'>JUNIOR специалисты</p></a>
                            </div>
                            <a href="https://t.me/jobs_for_it_juniors" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/jobs_for_it_juniors" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>

                        </div>

                        <div className="telegram_card item2">
                            <img src={Designers} alt="аватар" />
                            <div className="item-title item2">
                                <a href="https://t.me/Jobs_for_designers"><p className='name_UPP'>Дизайнеры</p></a>
                            </div>
                            <a href="https://t.me/Jobs_for_designers" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/Jobs_for_designers" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div >

                        <div className="telegram_card item3">
                            <img src={PM_PrdM} alt="аватар" />
                            <div className="item-title item3">
                                <a href="https://t.me/Jobs_aggregator_PM"><p className='name_UPP'>PM и PrdM</p></a>
                            </div>
                            <a href="https://t.me/Jobs_aggregator_PM" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/Jobs_aggregator_PM" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div>

                        <div className="telegram_card item4">
                            <img src={BackEnd} alt="аватар" />
                            <div className="item-title item4">
                                <a href="https://t.me/backend_dev_jobs_agregator"><p className='name_UPP'>Back-end dev</p></a>
                            </div>
                            <a href="https://t.me/backend_dev_jobs_agregator" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/backend_dev_jobs_agregator" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div>

                        <div className="telegram_card item5">
                            <img src={DevOps} alt="аватар" />
                            <div className="item-title item5">
                                <a href="https://t.me/+QjwAiEylInNhMDEy"><p className='name_UPP'>DevOps</p></a>
                            </div>
                            <a href="https://t.me/+QjwAiEylInNhMDEy" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/+QjwAiEylInNhMDEy" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div >

                        <div className="telegram_card item6">
                            <img src={MobileDev} alt="аватар" />
                            <div className="item-title item6">
                                <a href="https://t.me/+Zwfw9SzOojI3ZjVi"><p className='name_UPP'>Mobile dev</p></a>
                            </div>
                            <a href="https://t.me/+Zwfw9SzOojI3ZjVi" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/+Zwfw9SzOojI3ZjVi" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div >

                        <div className="telegram_card item7">
                            <img src={HR} alt="аватар" />
                            <div className="item-title item7">
                                <a href="https://t.me/+-Jg3LAn5Wt45NmJi"><p className='name_UPP'>IT HR</p></a>
                            </div>
                            <a href="https://t.me/+-Jg3LAn5Wt45NmJi" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/+-Jg3LAn5Wt45NmJi" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div>

                        <div className="telegram_card item8">
                            <img src={QA} alt="аватар" />
                            <div className="item-title item8">
                                <a href="https://t.me/qa_job_agregator"><p className='name_UPP'>Manual & Auto QA</p></a>
                            </div>
                            <a href="https://t.me/qa_job_agregator" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/qa_job_agregator" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div>

                        <div className="telegram_card item9">
                            <img src={Sales} alt="аватар" />
                            <div className="item-title item9">
                                <a href="https://t.me/+R3w01li8sUk2ZTBi"><p className='name_UPP'>Продажи в сфере IT</p></a>
                            </div>
                            <a href="https://t.me/+R3w01li8sUk2ZTBi" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/+R3w01li8sUk2ZTBi" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div >


                        <div className="telegram_card item10">
                            <img src={Gamedev} alt="аватар" />
                            <div className="item-title item10">
                                <a href="https://t.me/+q5GC-q4o6gYxMzFi"><p className='name_UPP'>Gamedev (разработчики,дизайнеры)</p></a>
                            </div>
                            <a href="https://t.me/+q5GC-q4o6gYxMzFi" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/+q5GC-q4o6gYxMzFi" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div>

                        <div className="telegram_card item11">
                            <img src={Frontend} alt="аватар" />
                            <div className="item-title item11">
                                <a href="https://t.me/jobs_for_frontend"><p className='name_UPP'>Front-end dev</p></a>
                            </div>
                            <a href="https://t.me/jobs_for_frontend" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/jobs_for_frontend" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div>


                        <div className="telegram_card item12">
                            <img src={ITBisnesAnalitic} alt="аватар" />
                            <div className="item-title item12">
                                <a href="https://t.me/BA_jobs_agregator"><p className='name_UPP'>IT Бизнес и аналитики</p></a>
                            </div>
                            <a href="https://t.me/BA_jobs_agregator" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/BA_jobs_agregator" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div>

                        <div className="telegram_card item13">
                            <img src={Marketologs} alt="аватар" />
                            <div className="item-title item13">
                                <a href="https://t.me/+bDDQhL-19ERmMjky"><p className='name_UPP'>Маркетологи
                                    (SMM, Targeting, Creative, etc)</p></a>
                            </div>
                            <a href="https://t.me/+bDDQhL-19ERmMjky" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/+bDDQhL-19ERmMjky" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div>

                        <div className="telegram_card item14">
                            <img src={Aggregator} alt="аватар" />
                            <div className="item-title item14">
                                <a href="https://t.me/it_jobs_agregator"><p className='name_UPP'>IT jobs aggregator</p></a>
                            </div>
                            <a href="https://t.me/it_jobs_agregator" className="item-subscribe">Подробнее</a>

                            <a href="https://t.me/it_jobs_agregator" className="item-subscribe-mobile"><img className="vector_card-mobil" src={Vector_card} alt="card_mobil-button»" /></a>
                        </div >
                    </div >
                </div >
            </div>
        </div >
    )
}

export default Telegram;
