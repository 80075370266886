import { NavLink } from 'react-router-dom'
import React, { useState } from 'react'
import Tag from './Tag'
import { dateFormatForArticles } from '../../../helpers/dateFormat'
import likeIcon from '../../../assets/likes.svg'
import commentIcon from '../../../assets/comments.svg'
import shareGreyIcon from '../../../assets/shareGrey.svg'
import shareBlueIcon from '../../../assets/ShareBlue.svg'
import bookmarkGreyIcon from '../../../assets/bookmarGrey.svg'
import bookmarkBlueIcon from '../../../assets/bookmarkBlue.svg'
import { MenuShare } from '../../../common/MenuShare/MenuShare'

const ArticlesListItem = ({ article }) => {
  const [isBookmark, setIsBookmark] = useState(article.bookmark)
  const [shareHover, setShareHover] = useState(false)

  const shareUrl = window.location.href
  const [showMenuShare, setShowMenuShare] = useState(false)

  const shareOnClickHandler = () => {
    setShowMenuShare(true)
  }

  const addBookmarkOnClickHandler = () => {
    setIsBookmark(!isBookmark)
  }

  return (
    <>
      <article
        className="flex flex-col max-w-[1040px] max-h-[367px] p-[10px] text-[14px] font-semibold leading-[130%]
    sm:w-[343px] sm:max-h-max"
      >
        <div
          className="flex flex-row justify-between mx-[10px] relative
      sm:overflow-hidden"
        >
          {/* top of article */}
          <ul
              className="flex flex-row gap-x-[10px] my-[10px] sm:hidden"
            >
              {article.tags.map((tag, index) => (
                <div key={index} className="cursor-default">
                  <Tag text={tag} />
                </div>
              ))}
            </ul>
          <div
            className="flex flex-row justify-between h-[24px] w-[78px] my-[12px] items-center
        sm:hidden"
          >
            <img
              className="h-[20px] w-[19px] cursor-pointer"
              src={shareHover ? shareBlueIcon : shareGreyIcon}
              alt="shareIcon"
              onClick={shareOnClickHandler}
              onMouseEnter={() => setShareHover(true)}
              onMouseLeave={() => setShareHover(false)}
            />
            <img
              className="h-[24px] w-[15px] cursor-pointer"
              src={isBookmark ? bookmarkBlueIcon : bookmarkGreyIcon}
              alt="bookmarkIcon"
              onClick={addBookmarkOnClickHandler}
            />
          </div>
          {showMenuShare && (
            <div className="absolute w-28 right-[1%] top-3">
              <MenuShare
                url={`${shareUrl}/${article.id}`}
                title={article.title}
                modalShare={showMenuShare}
                closeMenuShare={() => setShowMenuShare(false)}
              />
            </div>
          )}
        </div>
        <div
          className="flex flex-row mt-[10px]
      sm:flex-col-reverse"
        >
          <div className="mr-[17px] sm:mt-2">
            {/* left side */}
            <div className="h-[38px] w-[125px] flex flex-col justify-center sm:items-start sm:p-[10px]">
              <p className="text-center  text-[#808099] sm:text-[12px] sm:font-medium">
                {dateFormatForArticles(new Date(article.dateOfCreate))}
              </p>
            </div>
            <div className="p-[10px]">
              <h4 className=" text-[#282A43]  tracking-[.008em] mb-[26px] sm:mb-2 sm:">
                {article.title}
              </h4>
              <p className=" text-[#4F4F70]  max-w-[595px] tracking-[.008em]">
                {article.text}
              </p>
            </div>
            
              <ul
                className="hidden sm:flex flex-row gap-x-[10px] my-[10px] ml-2"
              >
                {article.tags.map(
                  (tag, index) =>
                    index <= 1 && (
                      <div key={index} className="cursor-default">
                        <Tag text={tag} />
                      </div>
                    )
                )}
              </ul>
            <div className="flex flex-row justify-between max-h-[38px]">
              <div className="flex flex-row p-[10px] justify-between  text-[#BFD4FF] max-w-[291px] gap-x-[10px] sm:gap-x-5">
                <div className="flex flex-row max-w-[125px] sm:text-xs sm:items-end">
                  <div className="my-[5.25px] mr-[5px] sm:my-[2px] sm:mr-3">
                    <img
                      src={likeIcon}
                      alt="likeIcon"
                      className="w-[7.5px] h-[7.5px] sm:w-3 sm:h-3"
                    />
                  </div>
                  <div>{article.likes}</div>&nbsp;<div className="sm:hidden">понравилось</div>
                </div>
                <div className="flex flex-row max-w-[136px] sm:text-xs sm:items-end">
                  <div className="my-[5.25px] mr-[5px] sm:my-[2px] sm:mr-3">
                    <img
                      src={commentIcon}
                      alt="commentIcon"
                      className="w-[7.5px] h-[7.5px] sm:w-3 sm:h-3"
                    />
                  </div>
                  <div>{article.comments}</div>&nbsp;
                  <div className="sm:hidden">комментариев</div>
                </div>
              </div>
              <div className="p-[10px] sm:pr-0">
                <NavLink
                  to={`/it/articles/${article.id}`}
                  className="text-[#4985FF]"
                >
                <span className="hidden sm:block">Читать далее</span><span className="sm:hidden">Читать подробнее</span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="p-[10px] sm:p-0 sm:relative sm:-left-[10px]">
            {/* right side */}
            <img
              className="max-w-[368px] max-h-[269px] sm:w-[343px]"
              src={require(`../../../assets/${article.img}`)}
              alt={article.img}
            />
          </div>
        </div>
      </article>
    </>
  )
}

export default ArticlesListItem
