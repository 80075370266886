import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleModalWindow, setAllFiltersAndCategoriesIsUnactive } from '../../../../../store/reducers/EnterItArticlesReducer'
import BadgedButton from '../BadgedButton'
import crossIcon from '../../../../../assets/cross.svg'
import FiltersTagedList from './FiltersTagedList'
import CategoriesList from './CategoriesList'
import SubscribeToNotifications from './SubscribeToNotifications'
import { useOutsideClick } from '../../../../../helpers/useOutsideClick'
import FiltersIconForMobileActive from '../../../../../assets/filtersActive.svg'
import BadgedButtonForMobile from './../BadgedButtonForMobile'
 
const FiltersModalWindow = () => {
  const dispatch = useDispatch()
  const ref = useRef()
  const filtersCounter = useSelector((state) => state.enterItArticles.filtersCounter)
  const [showModal, setShowModal] = useState()

  useEffect(() => {
    setShowModal(true)
  }, [])

  const hideModal = () => {
    setShowModal(false)
    setTimeout(() => dispatch(toggleModalWindow()), 1000)
  }

  useOutsideClick(ref, hideModal, useSelector((state) => state.enterItArticles.showModal))

  const clearFilters = () => {
    dispatch(setAllFiltersAndCategoriesIsUnactive())
  }

  const applyFilters = () => {
    //applying
    setShowModal(false)
    setTimeout(() => hideModal(), 1000)
  }

  return (
    <div className="h-[100%] w-[100%]
                    fixed top-[92px] left-0
                    bg-[#CDE1FB] bg-opacity-10 backdrop-blur-[2.5px]
                    border-t-[1px] border-[#E7EEFF]
                    flex justify-end overflow-x-hidden overflow-y-auto
    ">
      <div ref={ref} className={`h-[90.6vh] w-[576px]
                    bg-white
                    border-[1px] border-[#E7EEFF] rounded-l-[24px] absolute -top-[3px]
                    py-10 px-11
                    flex flex-col justify-between
                    duration-1000 transition-all ${showModal ? " translate-x-0 " : " translate-x-full "}
                    sm:w-full sm:border-none sm:rounded-none sm:p-4
      `}>
        <div className="flex flex-col gap-y-[30px]">
          {/**buttons row */}
          <div className="h-[54px] w-[488px]
                          flex gap-x-[93px] items-end sm:hidden
        ">
            <div className="w-[249px] h-[41px]
                            flex gap-x-5
                            text-[14px] font-semibold leading-[130%]
          ">
              <button className="w-[145px] bg-[#4985FF] text-white rounded-[6px]" onClick={applyFilters}>
                Применить
              </button>
              <button className="w-[84px] text-[#4985FF]" onClick={clearFilters}>Очистить</button>
            </div>

            <BadgedButton
              icon={crossIcon}
              counter={filtersCounter}
              handler={hideModal}
            />
          </div>

          <div className="hidden sm:flex flex-row justify-between items-center text-[14px] font-semibold text-[#808099]">
            <button className="flex flex-row items-center w-[130px] h-[44px] gap-x-1" onClick={clearFilters}>
              <img src={crossIcon} alt="cross" className="w-3 h-3 m-[6px] ml-0"/>
              Очистить все
            </button>

            <BadgedButtonForMobile counter={filtersCounter} icon={FiltersIconForMobileActive} handler={hideModal}/>
          </div>

          <FiltersTagedList />
          <CategoriesList />
        </div>
        <div>
          <SubscribeToNotifications />
          <button className="hidden sm:block w-full h-[44px] bg-[#4985FF] text-white rounded-[6px] mt-3
                             text-[14px] font-semibold" onClick={applyFilters}>
            Применить
          </button>
        </div>
      </div>
    </div>
  )
}

export default FiltersModalWindow