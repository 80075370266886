import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import ArrowButton from './ArrowButton'
import NumberButton from './NumberButton'

import arrowPreviousGrey from '../../assets/previousGrey.svg'
import arrowPreviousBlue from '../../assets/previousBlue.svg'
import arrowNextGrey from '../../assets/nextGrey.svg'
import arrowNextBlue from '../../assets/nextBlue.svg'

const Pagination = ({ countOfPages }) => {
  
  const [searchParams] = useSearchParams();
  const [currentPageNumber, setCurrentPageNumber] = useState(searchParams.get('page') || 1);

  const location = useLocation()

  useEffect(() => {
    setCurrentPageNumber(searchParams.get('page') || 1)
  }, [location, searchParams])

  const pageNumbers = []

  const maxPagesToShow = 3;
  const startPage =  Math.max(currentPageNumber - Math.floor(maxPagesToShow / 2), 1)
  const endPage = Math.min(startPage + maxPagesToShow - 1, countOfPages)
 
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  if (startPage > 1) {
    pageNumbers.unshift('...');
    pageNumbers.unshift(1);
  }

  if (endPage < countOfPages) {
    pageNumbers.push('...');
    pageNumbers.push(countOfPages);
  }
  
  return (
    <ul className="h-[38px] mb-[40px]
                   flex flex-row justify-between gap-x-3 
                   text-[14px] font-semibold text-[#BEBFD2]">
      <ArrowButton 
        arrowImage={arrowPreviousGrey} 
        hoverImage={arrowPreviousBlue} 
        url={`?page=${+currentPageNumber > 1 ? +currentPageNumber - 1 : +currentPageNumber}`} />
      {
        pageNumbers.map((page, index) => <NumberButton key={index} pageNumber={page} isActive={+page === +currentPageNumber}/>)
      }
      <ArrowButton 
        arrowImage={arrowNextGrey} 
        hoverImage={arrowNextBlue} 
        url={`?page=${+currentPageNumber < countOfPages ? +currentPageNumber + 1 : +currentPageNumber}`}/>
    </ul>
  )
}

export default Pagination
