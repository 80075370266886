import { useState } from "react";

export default function Toggle({title}) {
    const [enabled, setEnabled] = useState(false);

    return (
        <div className="overflow-hidden mt-[10px]">
            <div className="flex">
            <span className="text-sm font-semibold text-gray mr-[6px]">
                        {title}
                    </span>
                <label className="inline-flex relative items-center cursor-pointer">
                    <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={enabled}
                        readOnly
                    />
                    <div
                        onClick={() => {
                            setEnabled(!enabled);
                        }}
                        className="w-11 h-6 bg-slate-300 rounded-full peer  peer-focus:ring-skyblue  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-buttonblue"
                    ></div>
                 
                </label>
            </div>
        </div>
    );
}