import React, { useRef } from "react";
import { useOutsideClick } from "../../helpers/useOutsideClick";

export const MenuMore = ({handlePrint, showMore, handleDownload, closeMenuMore}) => {
  const menuMoreRef = useRef(null);
	useOutsideClick(menuMoreRef, closeMenuMore, showMore);

  return (
    <div ref={menuMoreRef} className={`absolute right-0 flex flex-col items-start text-darkSecondary h-fit w-33 top-12 px-6 py-8 gap-9 mr-4 bg-white border border-divideGray rounded z-10 `}>
      <div onClick={handleDownload} className="flex flex-col items-center gap-y-2 hover:cursor-pointer hover:text-buttonblue transition-all">
        Скачать
      </div>
      <div onClick={handlePrint} className="flex flex-col items-center gap-y-2 hover:cursor-pointer hover:text-buttonblue transition-all">
        Распечатать
      </div>
    </div>
  )

}