import React from 'react'
import ArticlesListItem from './ArticlesListItem'
import ArticlesListItemYellowBorder from './ArticlesListItemYellowBorder'


const ArticlesList = ({ articles }) => {
  
  return (
    <section className="mb-10">
      {articles.map((article, index) =>
        article.important ? (
          <ArticlesListItemYellowBorder
            key={article.id}
            article={article}
          />
          ) : (
          <React.Fragment key={article.id}>
            <ArticlesListItem
              key={article.id}
              article={article}
            />
            {
              index !== articles.length - 1 && (
                <div className="h-[1px] w-[616px] bg-[#E7EEFF] mt-[20px] mb-[30px]
                                sm:mb-[2px] sm:ml-8 sm:mt-[23px] sm:w-[280px]"></div>
              )
              /*don't show devider if last of list*/
            }
          </React.Fragment>
        )
      )}
    </section>
  )
}

export default ArticlesList
