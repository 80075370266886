import React, { useRef } from "react";
import {
  LinkedinShareButton, LinkedinIcon,
  TelegramShareButton, TelegramIcon,
  WhatsappShareButton, WhatsappIcon,
} from "react-share";
import { useOutsideClick } from "../../helpers/useOutsideClick";

export const MenuShare = ({ url, title, modalShare, closeMenuShare }) => {
  const shareRef = useRef(null);
  useOutsideClick(shareRef, closeMenuShare, modalShare);

  return (
    <div ref={shareRef} className="absolute bottom-[-80px] w-full flex flex-wrap justify-center items-center gap-1 border border-divideGray rounded bg-white p-3 lg:bottom-[50px]">
      <TelegramShareButton url={url} quote={title}>
        <TelegramIcon size={24} round />
      </TelegramShareButton>
      <LinkedinShareButton url={url} quote={title}>
        <LinkedinIcon size={24} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={24} round />
      </WhatsappShareButton>
    </div>
  );
};