import * as yup from "yup";
import { emailValidSchema, passwordValidSchema } from "../../../../../..//validation/validation";

export const regValidSchema = yup.object().shape({
  ...emailValidSchema.fields,
  ...passwordValidSchema.fields,
  conditions: yup
    .bool()
    .oneOf([true], "Необходимо принять условия пользовательского соглашения"),
}).required();
