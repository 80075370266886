import React, { useRef, useState } from "react";
import schedule from "../../assets/schedule.png";
import location from "../../assets/location.png";
import workplace from "../../assets/workplace.png";
import grade from "../../assets/grade.png";
import { dateFormat } from "../../helpers/dateFormat";
import logo from "../../assets/footprint-light.png";
import more from "../../assets/more-points.png";
import back from "../../assets/back-arrow.png";
import { SidebarVacancy } from "../../common/SidebarVacancy/SidebarVacancy";
import { MenuMore } from "../../common/MenuMore/MenuMore";
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from "react-redux";
import './JobDetails.css'

const JobDetails = ({ chosenJob, vacancyRef, setChosenJob, handleBookmarkClick, bookmarked}) => {
	const [modalShare, setModalShare] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const isHidden = true;
	const dispatch = useDispatch()

	const closeMenuMore = () => {
		setShowMore(false);
	}
	const closeMenuShare = () => {
		setModalShare(false);
	}

	const handleDownload = () => {
		closeMenuMore();
		html2pdf(vacancyRef.current, {
			margin: 0.5,
			filename: `${chosenJob.vacancy}.pdf`,
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { dpi: 192, letterRendering: true },
			jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', putOnlyUsedFonts:true, floatPrecision: 12 }
		})
	};

	const handlePrint = useReactToPrint({
    	content: () => {
			return vacancyRef.current;
		},
		documentTitle: chosenJob.vacancy,
		onBeforePrint: closeMenuMore,
  });

	const handleShare = (e) => {
  	setModalShare((v) => !v);
  };

	return (
		<div ref={vacancyRef} id={chosenJob.id} className={`row-start-2 row-end-13 col-start-2 ml-1 col-end-13 overflow-y-auto border-l border-[#E7EEFF] flex flex-col items-center pl-8 pr-11 text-sm font-semibold relative pb-10 sm:mt-[15px] sm:row-start-1 lg:border-l-0  lg:px-4 lg:ml-0 lg:h-fit lg:pb-24  sm:col-start-1 sm:row-start-2`}>
			<div className="hidden justify-between w-full mt-3 h-14 px-2 lg:flex sm:mt-0">
				<button onClick={() => dispatch(setChosenJob({}))}>
					<img src={back} alt="Назад" />
				</button>
				<button onClick={() => setShowMore((v) => !v)}>
					<img src={more} alt="Больше" />
				</button>
				{showMore &&
					<MenuMore
						handleDownload={handleDownload}
						showMore={showMore}
						handlePrint={handlePrint}
						closeMenuMore={closeMenuMore}
					/>}
			</div>

			<div className="self-start text-gray ml-5 mt-3 lg:self-end lg:m-0">
				{dateFormat(chosenJob.time_of_public)}
			</div>

			<SidebarVacancy
				handleBookmarkClick={handleBookmarkClick}
				bookmarked={bookmarked}
				vacancyRef={vacancyRef}
				handleShare={handleShare}
				modalShare={modalShare}
				chosenJob={chosenJob}
				handleDownload={handleDownload}
				handlePrint={handlePrint}
				closeMenuShare={closeMenuShare}
			/>

			<div className="flex flex-col items-center mt-2 w-[55%] sm:w-[75%] lg:mt-4 lg:w-2/3">
				<div className="mb-3 w-[74px] sm:mb-2 sm:w-[35px]">
					{chosenJob.logo ? <img src={chosenJob.logo} alt={chosenJob.company} className="w-full" /> : <img src={logo} alt={chosenJob.company} className="w-full" />}
				</div>
				<div className="custom-company">{chosenJob.company}</div>

				{chosenJob.rating && (
					<div className="flex">
						<p className="ml-2 font-light m-1 dark:text-slate-500">
							{chosenJob.rating} из 5
						</p>
						{chosenJob.rating && [...Array(chosenJob.rating)].map((i, index) => (
							<svg
								key={index}
								aria-hidden="true"
								className="w-5 h-5 text-yellow-400"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title>First star</title>
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
							</svg>
						))}
						{chosenJob.rating && chosenJob.rating !== 5
							? [...Array(5 - chosenJob.rating)].map((i, index) => (
									<svg
										key={index}
										aria-hidden="true"
										className="w-5 h-5 text-slate-200 dark:text-gray-500"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<title>Fifth star</title>
										<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
									</svg>
								))
							: ""}
						<span className="text-buttonblue cursor-pointer m-[3px]">
							18 отзывов
						</span>{" "}
					</div>
				)}
<div className="pdf-wrapper">
	<div class="pdf-line"></div>
  <h3 className="pdf-title">{chosenJob.vacancy}</h3>
  <div className='pdf-salary'>{chosenJob.salary}</div>
  <p className="pdf-job-type">{chosenJob.job_type}</p>
</div>
			</div>
			<div className='pdf-label-full-wrapper '>
				<div className="pdf-label-wrapper">
					{chosenJob.level && (
						<div style={{ width: '35%' }} className="pdf-label-inner">
							<div className="pdf-icon">
								<img src={grade} alt="Квалификация" className="w-full"/>
							</div>
							{<span className="pdf-label">{chosenJob.level}</span>}
						</div>
					)}
					{chosenJob.job_type && (
						<div style={{ width: 'auto', paddingRight: '25px' }} className="pdf-label-inner">
							<div className="pdf-icon">
								<img src={workplace} alt="Условия работы" />
							</div>
							<span className="pdf-label">{chosenJob.job_type}</span>
						</div>
					)}
					{chosenJob.city && (
						<div style={{ width: '100%' }} className="pdf-label-inner">
							<div className="pdf-icon">
								<img src={location} alt="Локация" />
							</div>
							<span style={{ width: '100%' }} className="pdf-label">{chosenJob.city}</span>
						</div>
					)}
					{chosenJob.typeOfEmployment && (
						<div className="pdf-label-inner">
							<div className="pdf-icon">
								<img src={schedule} alt="Тип занятости" />
							</div>
							<span className="pdf-label">{chosenJob.typeOfEmployment}</span>
						</div>
					)}
				</div>
			</div>

			<div className="flex justify-between w-full mt-8 sm:mt-4">
				{!isHidden && <div className="flex justify-start flex-col self-start sm:flex-row sm:px-1.5 sm:gap-x-2">
					<p className="text-gray ">На эту вакансию откликнулись:</p>
					<p className="text-buttonblue ">2 человека</p>
				</div>}
				<button
					type="button"
				className="pdf-button"
				style={{
					margin: '0',
				}}
				>
					<a href={chosenJob.vacancy_url} target="_blank" className="pdf-link" rel='noreferrer'>Откликнуться</a>
				</button>
			</div>
			<div className="h-[1px] bg-divideGray w-full my-5 sm:my-4"></div>
			<div className="text-dark leading-[130%] sm:px-2.5">{chosenJob.body}</div>
			{chosenJob.duties && (
				<div className="flex self-start flex-col">
					<p>
						<strong>Обязанности:</strong>
					</p>
					{chosenJob.duties}
				</div>
			)}
			{chosenJob.requirements && (
				<div className="flex self-start flex-col">
					<p>
						<strong>Требования:</strong>
					</p>
					{chosenJob.requirements && chosenJob.requirements.map((skill, i) => (
						<li key={i}>{skill}</li>
					))}
				</div>
			)}
			{chosenJob.desirable && (
				<div className="flex self-start flex-col">
					<p>
						<strong>Желательно:</strong>
					</p>
					{chosenJob.desirable && chosenJob.desirable.map((skill, i) => (
						<li key={i}>{skill}</li>
					))}
				</div>
			)}
			{chosenJob.conditions && (
				<div className="flex self-start flex-col">
					<p>
						<strong>Условия:</strong>
					</p>
					{chosenJob.conditions && chosenJob.conditions.map((skill, i) => (
						<li key={i}>{skill}</li>
					))}
				</div>
			)}
			<div>
				<button
					type="button"
					className="pdf-button"
				>
					<a href={chosenJob.vacancy_url} target="_blank" rel='noreferrer' className="pdf-link">Откликнуться</a>
				</button>
			</div>
		</div>
	);
};

export default JobDetails;
