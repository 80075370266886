import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Selector from './Selector'
import FilterIcon from '../../../../assets/filterIcon.svg'
import { toggleModalWindow } from '../../../../store/reducers/EnterItArticlesReducer'
import BadgedButton from './BadgedButton'


const FiltersArticle = () => {  
  const dispatch = useDispatch()
  const showModal = () => dispatch(toggleModalWindow())
  const filtersCounter = useSelector(state => state.enterItArticles.filtersCounter)

  return (
    <div className="w-[398px] h-[60px] flex flex-row mt-[24.5px] mb-[15.5px] mr-[44px] gap-x-[32px] sm:hidden">
      <Selector />
      <BadgedButton icon={FilterIcon} counter={filtersCounter} handler={showModal}/>
    </div>
  )
}

export default FiltersArticle
