import React from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/sidebar/Sidebar";
import { routes } from "../../routes/routes";
import { Link } from "react-router-dom";
import breadcrumb from "../../assets/breadcrumb.png";
import CompanyContacts from "./CompanyContacts";
import back from "../../assets/back.png";
function TermsOfUse() {
	return (
		<div className="grid grid-cols-12 grid-rows-12 h-screen gap-0 ">
			<Header
				headerContent={
					<nav class="flex  ml-[-10%]" aria-label="Breadcrumb">
						<ol class="inline-flex items-center space-x-1 md:space-x-3  text-base">
							<li>
								<div class="flex items-center">
									<Link
										to={routes.documents}
										class="ml-1  font-medium text-slate-300 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
									>
										Документы
									</Link>
								</div>
							</li>
							<li aria-current="page">
								<div class="flex items-center">
									<img src={breadcrumb} />
									<span class="ml-1 font-medium text-gray-500 md:ml-2 dark:text-gray-400">
										Пользовательское соглашение
									</span>
								</div>
							</li>
						</ol>
					</nav>
				}
			/>
			<Sidebar />
			<CompanyContacts />
			<section className="col-start-6 row-start-3 col-end-11">
				<div className="flex">
					<div className="flex items-center mr-3"><img src={back} /></div>
					<div><Link to={routes.documents}>
						{" "}
						<span className="text-[#808099]">Вернуться назад</span>
					</Link>
				</div></div>
				<article className="text-[#4F4F70]">
					<h2>Пользовательское соглашение</h2>{" "}
					<p className="">
						Приглашаем опытных backend-разработчиков (Python, Java)
						присоединиться к команде EPAM за 24 часа.
					</p>
					<p className="py-5">
						Никаких многодневных интервью — мы ценим ваше и свое время, поэтому
						вы пообщаетесь только с теми, кто имеет непосредственное отношение к
						проектам.
					</p>
					<p>
						Если мы подходим друг другу, вы получите оффер в течение суток и
						дополнительный welcome-бонус в размере одного оклада.
					</p>
					<p className="py-5">
						В EPAM вы сможете выбирать проекты и задачи, ориентируясь на
						интересы и цели своего развития. А также поработать с крупными
						международными заказчиками из разных отраслей: медицины, финтеха,
						торговли, Life Sciences, Big Data.
					</p>
					<p>
						Вас ждет карьерный рост в треке Advanced Software Engineer, если вы
						любите писать код и не видите себя в роли менеджера. А также
						реализация амбициозных идей: от проработки архитектуры до внедрения
						в эксплуатацию.
					</p>
				</article>
			</section>
		</div>
	);
}

export default TermsOfUse;
