import * as yup from "yup";

export const emailValidSchema = yup.object({
  email: yup
    .string()
    .required("Это обязательное поле")
    .email("Некорректный email")
    .matches(/^.{3,}@/, "Имя пользователя слишком короткое"),
});

export const passwordValidSchema = yup.object({
  password: yup
    .string()
    .required("Введите пароль")
    .min(8, "Минимальная длина пароля - 8 символов")
    .matches(/[A-Za-zА-Яа-яЁё]/, "Пароль должен содержать хотя бы одну букву")
    .matches(/[A-ZА-ЯЁ]/, "Пароль должен содержать хотя бы одну заглавную букву")
    .matches(/[a-zа-яё]/, "Пароль должен содержать хотя бы одну строчную букву")
    .matches(/[0-9]/, "Пароль должен содержать хотя бы одну цифру")
    .matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/, "Пароль должен содержать спец. символы")
});
