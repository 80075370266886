import React from 'react';
import Checkbox from '../../../common/Checkbox/Checkbox';
import Select from '../../../common/Select/Select';
import { countryList } from '../../../assets/mock.js'
import Toggle from '../../../common/Toggle/Toggle';
import cross from '../../../assets/cross.png'

const Modal = ({showModal, setShowModal}) => {

    const specialization = ["Разработка", "Дизайн", "HR", "Маркетинг", "Менеджмент", "Продажи", "Аналитика", "Карьера", "Другое"]

    return (
      <>
			<div className="no-scrollbar flex justify-end backdrop-blur-sm overflow-y-auto mt-[3%] fixed inset-0 z-30 outline-none focus:outline-none" onClick={() => setShowModal(false)}>
				<div className="relative w-1/2"  onClick={(e) => {e.stopPropagation()}}>
					<div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none" >
                        <div>
                            <div className="flex justify-start w-full my-7">
                                    <button 
                                        className="bg-transparent border-0 text-black float-right self-start"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <img src={cross} alt="cross" className="text-blue opacity-7 h-6 w-6 text-xl block bg-gray-400 py-0 rounded-full"/>
                                    </button>
                                    <button className=" bg-buttonblue text-white font-medium mx-5 text-[14px] rounded border w-1/4 h-[50px]">
                                        Показать 35 курсов
                                    </button>
                                    <button className="bg-transparent border-0 text-gray text-base flex mt-3">
                                        <img src={cross} alt="cross" className="text-blue opacity-7 h-6 w-6 text-xl block bg-gray-400 py-0 rounded-full mr-2"/>
                                        <span>Отменить все</span>
                                    </button>
                            </div>
                            <div className="flex ml-12 mb-10">
                                <div className="flex flex-col w-1/5">
                                    <div className="mb-5">
                                        <label className="block text-sm font-medium text-gray">
                                            Стоимость
                                        </label>
                                        <select className="block py-2.5 px-0 w-full text-sm fill='none' bg-select bg-no-repeat bg-right border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                            <option value="3d">по убыванию</option>
                                            <option value="7d">по возрастанию</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label   label className="block text-sm font-medium text-gray">
                                            Дата начала
                                        </label>
                                        <select className="block py-2.5 px-0 w-full text-sm fill='none' bg-select bg-no-repeat bg-right border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                            <option value="3d">ближайшие</option>
                                            <option value="7d">все</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-col mx-20">
                                    <h3 className="mb-5 text-lg text-[#808099]">Формат</h3>
                                    <div>
                                        <Checkbox label="Online"/>
                                        <Checkbox label="Offline"/>
                                    </div>
                                    <div>
                                        <h3 className="text-gray text-lg">Локация:</h3>
                                        <Select
											label="Страна"
											options={countryList}
											defaultValue="Все страны"
										/>
										<Select
											label="Город"
											options={countryList}
											defaultValue="Все города"
										/>
										<Select
											label="Штат"
											options={countryList}
											defaultValue="Штат"
										/>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <h3 className="mb-5 text-lg text-[#808099]">
                                        Стоимость
                                    </h3>
                                    <div className="mb-10">
                                        <div className="mb-4">
                                            <span>От</span>
                                            <input
                                                type="text"
                                                className="w-1/5 border-gray rounded border-solid border-2 mx-4"
                                            />
                                            <span>До</span>
                                            <input
                                                type="text"
                                                className="w-1/5 border-gray rounded border-solid border-2 mx-4"
                                            />
                                        </div>
                                        <div className="flex">
                                            <Checkbox/>
                                            <span>Бесплатно</span>
                                        </div>
                                    </div>
                                    <h3 className="mb-3 text-lg text-[#808099]">
                                        Дата проведения
                                    </h3>
                                    <div className="flex flex-col">
                                        <div className="flex mb-4">
                                            <span className="mr-2.5">C</span>
                                            <input type="date"  className="w-28 border-gray rounded border-solid border-2 mx-2"/>
                                        </div>
                                        <div className="flex">
                                            <span>По</span>
                                            <input type="date"  className="w-28 border-gray rounded border-solid border-2 mx-2"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end border-b-2 border-[#BFD4FF] mb-3">
                                <div className="flex flex-col items-end w-1/2 mr-10">
                                    <div className="flex justify-between w-full">
                                        <h2 className="mr-20 text-lg text-[#808099]">
                                            Специализация
                                        </h2>
                                        <button>
                                            Выбрать все
                                        </button>
                                    </div>
                                    <div className="w-full flex flex-wrap">
                                        {specialization.map((speciality) => (
                                                <button className="mr-5 border-2 border-skyblue rounded-2xl px-5 py-1 mb-5">{speciality}</button>                       
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex p-2">
                                <Toggle title="Подписаться на уведомления по данным критериям"/>
                            </div>
                        </div>	
					</div>
				</div>
			</div>
		</>
    );
};

export default Modal;