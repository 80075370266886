import Sidebar from '../../common/sidebar/Sidebar'
import Header from '../../common/Header/Header'
import placeholderCoty from '../../assets/placeholderCoty.png'

export function Placeholder() {
  return (
    <div className='homePageContainer'>
      <Sidebar />
      <div className="mainContentContainer mx-3">
        <Header
          headerContent={''}
          className={''}
        />
        <div className="text-center pt-20">
        <h2 className="font-raleway font-semibold text-midnight text-[40px] leading-[48px] mb-[72px] opacity-50">Страница в разработке</h2>
          <img
            src={placeholderCoty}
            alt=""
            className="max-w-full m-auto"
          />
        </div>
      </div>
    </div>
  );
};
