import { RegistrationForm } from "./RegistrationForm/RegistrationForm";
import { RegAuthLayout } from "../RegAuthLayout";

export default function Registration() {
  return (
    <RegAuthLayout>
      <RegistrationForm />
    </RegAuthLayout>
  );
};
