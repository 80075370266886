import $api from "../http/AuthAPI";

export default class AuthService {
	static async registration(email, password) {
		return await $api.post("/register/", { email, password });
	}
	static async login(email, password) {
		return $api.post("/login/", { email, password });
	}
	static async logout() {
		return $api.post("/logout/");
	}
}
