import React, { useState } from 'react';
import epam from '../../assets/epam.png'
import bookmark from '../../assets/bookmark.png';
import share from '../../assets/share.png';
import ModalEvents from './ModalEvents';

const EventsList = () => {
    const [showModal, setShowModal] = useState(false);
    const events = [
        {
            id: "1",
            date: "25.03",
            time: "17:00",
            place: "Online",
            cost: "12$",
            img: epam,
            company: "Epam",
            title: "Senior Backend Hiring Week: возможность получить оффер за 24 часа",
            level: 'Начало карьеры',
            direction: 'Frontend',
            elected: "20 добавили в избронное"
        },
        {
            id: "2",
            date: "25.03",
            time: "17:00",
            place: "Online",
            cost: "12$",
            img: epam,
            company: "Epam",
            title: "Senior Backend Hiring Week: возможность получить оффер за 24 часа",
            level: 'Начало карьеры',
            direction: 'Frontend',
            elected: "20 добавили в избронное"
        },
        {
            id: "3",
            date: "25.03",
            time: "17:00",
            place: "Online",
            cost: "12$",
            img: epam,
            company: "Epam",
            title: "Senior Backend Hiring Week: возможность получить оффер за 24 часа",
            level: 'Начало карьеры',
            direction: 'Frontend',
            elected: "20 добавили в избронное"
        },
        {
            id: "4",
            date: "25.03",
            time: "17:00",
            place: "Online",
            cost: "12$",
            img: epam,
            company: "Epam",
            title: "Senior Backend Hiring Week: возможность получить оффер за 24 часа",
            level: 'Начало карьеры',
            direction: 'Frontend',
            elected: "20 заинтересовались"
        },
    ]
    return (
        <div className="container mx-auto w-[45vw] h-[1200px] flex flex-col items-center">
            {showModal ? <ModalEvents showModal={showModal} setShowModal={setShowModal}/> : null}
            {events.map (event => (
                <div key={event.id} className="flex mb-10 px-10 py-10 w-[1340px]">
                    <div className="flex flex-col mr-14"> 
                        <div className="flex flex-col items-center mb-16">
                            <p className="mb-1">{event.date}</p>
                            <p className="text-xs">{event.time}</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <p className="mb-1 text-xs">{event.place}</p>
                            <p className="text-xs">{event.cost}</p>
                        </div>
                    </div>
                    <div className="flex flex-col items-center mr-14">
                        <img src={event.img} className="mb-6 mt-2 w-[130px] mb-3"/>
                        <p>{event.company}</p>
                    </div>
                    <div className="flex flex-col justify-between mr-14">
                        <h3 className="text-2xl font-medium mb-7">{event.title}</h3>
                        <button className="text-skyblue text-left font-semibold mb-7">Подробнее</button>
                        <div className="flex">
                            <p className="border-2 border-gray rounded-3xl px-[18px] py-[6px] text-gray mr-2">
                                {event.level}
                            </p>
                            <p className="border-2 border-gray rounded-3xl px-[18px] py-[6px] text-gray">
                                {event.direction}
                            </p>
                        </div>
                    </div>
                    <div className="w-1/6 flex flex-col justify-between">
                        <div className="flex items-start">
                            <img
                                src={bookmark}
                                className="mr-5  opacity-20 hover:opacity-100"
                                alt="save"
                            />
                            <img src={share} />
                        </div> 
                        <div>
                            <p className="text-xs text-[#BEBFD2]">{event.elected}</p>
                        </div>
                    </div>
                </div>
            )

            )}
        </div>
    );
};

export default EventsList;