import React from 'react'
// import { HomePageNews } from './HomePageNews'
import { useNavigate } from 'react-router-dom'
import bookmark from '../../assets/bookmark.png'
import selectCourse from '../../assets/selectCourse.png'
import showmore from '../../assets/showmore.png'
import Header from '../../common/Header/Header'
import Sidebar from '../../common/sidebar/Sidebar'
import Carousel from '../../common/Swiper/Carousel'
import VacanciesAPI from '../../http/VacanciesAPI'
import './Home.css'
import { HomePageInfo } from './HomePageInfo'
import { HomePageInternships } from './HomePageInternships'
import { HomePageVacancies } from './HomePageVacancies'
import { useDispatch } from 'react-redux'
import { removeAllVacancies } from '../../store/reducers/VacanciesReducer'
import { routes } from "../../routes/routes";

export default function Home() {
	const [newCommonVacancies, setNewCommonVacancies] = React.useState(null)
	const [newTraineeVacancies, setNewTraineeVacancies] = React.useState(null)
	let navigate = useNavigate()
	const dispatch = useDispatch();

	const handleButtonClicked = (titleSlide) => {
    switch (titleSlide) {
      case 'Telegram':
        navigate(routes.telegram);
        break;
      case 'Работа в IT':
        navigate(routes.jobs);
        break;
      default:
        navigate(routes.placeholder);
        break;
    }
  };

	const changeRoute = (vacancy) => {
		// navigate(routes.temporary)
		navigate(`/jobs/${vacancy.id}`, { state: {vacancyHome: vacancy} });
		dispatch(removeAllVacancies());
	}

	const fetchNewVacancies = async () => {
		try {
			VacanciesAPI.getNewVacancies()
				.then(({ common_vacancies, vacancies }) => {
					setNewCommonVacancies(() => Object.assign([], common_vacancies))
					setNewTraineeVacancies(() => Object.assign([], vacancies))
				})
				.catch(error => {
					console.log(error)
				})
		} catch (error) {
			console.log(error)
		}
	}

	React.useEffect(() => {
		fetchNewVacancies()
	}, [])

	return (
		<div className='homePageContainer'>
			<Sidebar />

			<div className='mainContentContainer'>
				<Header
					headerContent={''}
					className={''}
				/>
				<Carousel handleButtonClicked={handleButtonClicked} />

				<div className='mainContent'>
					<HomePageVacancies
						newCommonVacancies={newCommonVacancies}
						bookmark={bookmark}
						showmore={showmore}
						changeRoute={changeRoute}
					/>

					<HomePageInternships
						newTraineeVacancies={newTraineeVacancies}
						bookmark={bookmark}
						showmore={showmore}
						changeRoute={changeRoute}
					/>

					<HomePageInfo selectCourse={selectCourse} />
				</div>
			</div>
		</div>
	)
}
