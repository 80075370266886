import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { toggleModalWindow } from '../../../store/reducers/EnterItArticlesReducer'

import ArticlesList from './ArticlesList'
import Pagination from '../../../common/Pagination/Pagination'
import FiltersModalWindow from './Filters/FiltersModalWindow/FiltersModalWindow'
import SearchBarForMobile from './Filters/SearchBarForMobile';

const Articles = () => {
  const articles = useSelector(state => state.enterItArticles.articles)
  const totalSize = useSelector(state => state.enterItArticles.totalCount)
  const countPerPage = useSelector(state => state.enterItArticles.itemsPerPage)
  const showModal = useSelector(state => state.enterItArticles.showModal)
  const totalPages = Math.ceil(totalSize / countPerPage)

  const dispatch = useDispatch()
  const showModalFiltersForMobile = () => dispatch(toggleModalWindow())

  return (
    <>
      <div className="w-full flex flex-row justify-end pr-[44px] sm:hidden">
        <Pagination countOfPages={totalPages}/>
      </div>
      <SearchBarForMobile filtersButtonHandler={showModalFiltersForMobile}/>
      <ArticlesList articles={articles}/>
      <Pagination countOfPages={totalPages}/>
      { showModal && <FiltersModalWindow /> }
    </>
  )
}

export default Articles
