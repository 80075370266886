import React, {useState} from 'react'

const SubscribeToNotifications = () => {
  return (
    <div className="h-7 border-t border-[#BFD4FF] pt-[10px] h-auto">
      <div className="flex gap-x-[10px]">
        <div className="text-[14px] font-semibold leading-[130%] text-[#808099] tracking-[0.006em]">
          Подписаться на уведомления по данным критериям
        </div>
        <ToggleSubscribe />
      </div>
    </div>
  )
}

const ToggleSubscribe = () => {
    const [isSelected, setIsSelected] = useState(false)
    return (
      <div className={
          "flex min-w-[33px] h-[16px] "+
          "rounded-full p-[3px] cursor-pointer transition-all duration-500" +
          (isSelected ? " bg-[#4985FF] justify-end" : " bg-[#E7EEFF]")
        }
        onClick={() => setIsSelected(!isSelected)}
      >
        <span className="h-[10px] w-[10px] bg-white rounded-full transition-all duration-1000"/>  
      </div>
    )
}

export default SubscribeToNotifications