import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { routes } from '../../routes/routes';
import FilterElement from './Courses/CoursesFilter';
import FiltersArticle from './Articles/Filters/FiltersArticle';
import EventsFilter from '../Events/EventsFilter';
import InternshipsFilter from '../Internships/InternshipsFilter';

const setActive = ({isActive}) => isActive ? "font-bold border-b-2 border-skyblue" : ""

const FilterBar = ({ showModal, setShowModal }) => {
    const {pathname} = useLocation()

    const renderFilter = (pathname) => {
        switch(pathname) {
            case "/it/courses":
                return <FilterElement showModal={showModal} setShowModal={setShowModal}/>
            case "/it/articles":
              return <FiltersArticle />
            // case "/it/event":
            //     return <EventsFilter showModal={showModal} setShowModal={setShowModal}/>;
            // case "/it/internship":
            //     return <InternshipsFilter showModal={showModal} setShowModal={setShowModal}/>;
            default:
                return ""
        }

   
    }
    return (
        <div>
            <div className="flex justify-between text-xl shadow-bottom pb-3 mb-10 h-[98px] sm:text-sm">
              <div className="container "> {/* removed from className mt-[100px] ml-40 */}
                <ul className="flex">
                  <li className="mr-5">
                    <NavLink to={routes.courses} className={setActive}>Курсы</NavLink>
                  </li>
                  <li className="mr-5">
                    <NavLink to={routes.enterITArticles} className={setActive}>Статьи</NavLink>
                  </li>
                  <li className="mr-5">
                    <NavLink to={routes.event} className={setActive}>События</NavLink>
                  </li>
                  <li className="mr-5">
                    <NavLink to={routes.internship} className={setActive}>Стажировки</NavLink>
                  </li>
                  {/* <li className="mr-5">
                    <NavLink to={routes.job} className={setActive}>Вакансии</NavLink>
                  </li> */}
                </ul>
              </div>
              <div>
                {renderFilter(pathname)}
              </div>
          </div>
        </div>
    );
};

export default FilterBar;