import Select, { components } from 'react-select'
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow.svg'
import { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom"

const Selector = () => {
  const options = [
    { value: 'desc', label: 'по убыванию' },
    { value: 'asc', label: 'по возрастанию' },
  ]

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator  {...props}>
        <ArrowIcon />
      </components.DropdownIndicator>
    )
  }

  const customStyles = {
    container: (defaultStyles) => ({
      ...defaultStyles,
      height: '42px'
    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? '#FFFFFF' : '#BEBFD2',
      backgroundColor: state.isSelected ? '#4985FF' : '#FFFFFF',
      border: 'none',
      fontSize: '14px',
    }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      boxShadow: 'none',
      fontSize: '14px',
      height: '41px',
      borderRadius: 'none',
      border: 'none',
      borderBottom: '1px solid #BFD4FF',
      verticalAlign: 'bottom'
    }),
    valueContainer: () => ({ padding: '0', height: '42px' }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: '#808099',
      fontWeight: '600',
      margin: '7px 0',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: () => ({ padding: '0 2px 0 0' })
  }

  const [currentSorting, setCurrentSorting] = useState(options[0])
  const [searchParams, setSearchParams] = useSearchParams({});

  const onChangeHandler = (selectedValue) => {
    searchParams.set('sort', selectedValue.value)
    setSearchParams(searchParams)
    setCurrentSorting(selectedValue)
  }

  useEffect(() => {
    !searchParams.get('sort') 
      && searchParams.set('sort', currentSorting.value) 
         setSearchParams(searchParams)
  }, [searchParams])

  return (
    <div className="flex flex-col w-[220px]">
      <label
        htmlFor="publicationDate"
        className="font-semibold text-[14px] text-[#BEBFD2] h-[18px] leading-[140%]"
      >
        Дата публикации
      </label>
      <Select
        onChange={onChangeHandler}
        options={options}
        styles={customStyles}
        defaultValue={currentSorting}
        components={{DropdownIndicator}}
        isSearchable={false}
      />
    </div>
  )
}

export default Selector